import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  MultiSelect,
  ReactSelect,
} from "@anchor/react-components/dist/lib/components";
import { listModel } from "../Model";
import { getAPI } from "../../../Services/APIservices";
import { useForm, Controller } from "react-hook-form";
interface GeneratorProps {
  handleGeneratedData: (data: any) => void;
  serviceList: listModel[];
}
const GenerateSimulation = ({ handleGeneratedData, serviceList }: GeneratorProps) => {
  const [vesselList, setVesselList] = useState<listModel[]>([]);
  const [isSelectLoader, setIsSelectLoader] = useState(false);
  const { handleSubmit, control, setValue, reset } = useForm({
    defaultValues: {
      vesselList: [],
      serviceList: [],
    },
  });
  const getVesseleList = async () => {
    setIsSelectLoader(true);
    const response = await getAPI(`dtco-api/dtcosummary/GetVesselDetails`);
    if (response) {
      const res = response?.map((item) => {
        return {
          label:item.label +" - "+ item.value,
          value: item.value,
        };
      });
      setVesselList(res);
      setIsSelectLoader(false);
    }
  };

  const onSubmit = (data: any) => {
    const { vesselList, serviceList= [], requestName } = data;
    const serviceID = serviceList?.map((item) => item.value);
    const payload = {
      serviceIds: serviceID,
      imos: [vesselList?.value ?? ""],
      requestName: requestName ?? "",
    };
    handleGeneratedData(payload);
    reset()
  };
  useEffect(() => {
    getVesseleList();
  }, []);
  return (
    <form data-testid="generate-data" onSubmit={handleSubmit(onSubmit)}>
      <div className="simulation-generation mt-3">
        <h6>Request simulation</h6>
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <Controller
                  name="vesselList"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      className="multi-select"
                      closeMenuOnSelect
                      customNoOptionsMessage={<p>Not a valid option</p>}
                      isLoading={isSelectLoader}
                      fit="medium"
                      id="selectVessel"
                      isClearable
                      isSearchable
                      onChange={(e) => {
                        onChange(e);
                      }}
                      label="Vessel Name"
                      name="vesselList"
                      options={vesselList}
                      orientation="vertical"
                      placeholder="Select by vessel"
                      required
                      suggestType="static"
                      value={value}
                      width={100}
                      aria-label="Vessel"
                    />
                  )}
                />
              </div>
              <div className="col-md-6">
                <Controller
                  name="serviceList"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <MultiSelect
                      className="react-select"
                      closeMenuOnSelect
                      errorMessage="This is error message"
                      fit="medium"
                      id="selectservice"
                      isClearable
                      isSearchable
                      onChange={(e) => {
                        onChange(e);
                      }}
                      label="Service Names(s)"
                      name="serviceList"
                      options={serviceList}
                      orientation="vertical"
                      placeholder="Select a Service from the list"
                      required
                      hasSelectAll={true}
                      value={value}
                      variant="default"
                      width={100}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-md-1">
            <Button
              appearance="default"
              id="primary"
              justifyItems="center"
              label="Request"
              name="primary"
              fit="medium"
              title="Proceed"
              width={100}
              type="submit"
              variant="filled"
            />
          </div>
        </div>
      </div>
    </form>
  );
};
export default GenerateSimulation;
