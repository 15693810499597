// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simulation-result-feedback {
  width: 500px;
  height: 98%;
  overflow-y: auto;
}
.simulation-result-feedback-card {
  font-size: small;
}
.simulation-result-feedback-comment {
  background-color: var(--color-mainBg);
}
.simulation-result-feedback-comment-edit {
  max-height: 180px;
}

#selectBox {
  padding: 0px 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/SimulationRequest/Components/SimulationResultFeedback/SimulationDataFeedback.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AAAF;AACE;EACE,gBAAA;AACJ;AACE;EACE,qCAAA;AACJ;AAAI;EACE,iBAAA;AAEN;;AAMA;EACE,iBAAA;AAHF","sourcesContent":["\n.simulation-result-feedback {\n  width: 500px;\n  height: 98%;\n  overflow-y: auto;\n  &-card {\n    font-size: small;\n  }\n  &-comment {\n    background-color: var(--color-mainBg);\n    &-edit {\n      max-height: 180px;\n    }\n  }\n}\n\n\n\n\n#selectBox {\n  padding: 0px 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
