export const InfoText = [
    {
        label: "Back Haul(Captura Intake (TEU))",
        details: "Capacity Cube / Manual Adjustment",
        value: ""
    },
    {
        label: "Back Haul (Partner Allocation (%))",
        details: "Capacity Cube / Manual Adjustment",
        value: ""
    },
    {
        label: "Back Haul (Partner Allocation (TEU))",
        details: "",
        value: ""
    },
    {
        label: "Back Haul (MSK allocation (TEU))",
        details: "",
        value: "Captura Intake (TEU) - Partner Allocation (TEU)"
    },
    {
        label: "Back Haul (MSK Dry Demand (TEU))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Back Haul (MSK Reefer Demand (FFE))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Back Haul (Dry CY (FFE))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Back Haul (Reefer CY (FFE))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Back Haul (Utilization)",
        details: "",
        value: "((MSK Reefer Demand (FFE) * 2) + MSK Dry Demand (TEU)) / MSK allocation (TEU)"
    },
    {
        label: "Back Haul (% to Nominal)",
        details: "",
        value: "((Captura Intake (TEU) - MSK allocation (TEU) + MSK Dry Demand (TEU)+(MSK Reefer Demand (FFE) * 2)) / Nominal Capacity"
    },
    {
        label: "Design class",
        details: "Data retrieved from schedule planner",
        value: ""
    },
    {
        label: "Daily CONS (MT)",
        details: "",
        value: "Round Trip CONS (MT)/ Round Trip (Days)"
    },
    {
        label: "gCo2",
        details: "Retrieved from Scdeule Planner",
        value: ""
    },
    {
        label: "Head Haul (Captura Intake (TEU))",
        details: "Capacity Cube / Manual Adjustment",
        value: ""
    },
    {
        label: "Head Haul (Partner Allocation (%))",
        details: "Captura / Manual Adjustment",
        value: ""
    },
    {
        label: "Head Haul (Partner Allocation (TEU))",
        details: "",
        value: ""
    },
    {
        label: "Head Haul (MSK allocation (TEU))",
        details: "",
        value: "Captura Intake (TEU) - Partner Allocation (TEU)"
    },
    {
        label: "Head Haul (MSK Dry Demand (TEU))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Head Haul (MSK Reefer Demand (FFE))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Head Haul (Dry CY (FFE))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Head Haul (Reefer CY (FFE))",
        details: "FBR / Manual Adjustment",
        value: ""
    },
    {
        label: "Head Haul (Utilization)",
        details: "",
        value: "((MSK Reefer Demand (FFE) * 2) + MSK Dry Demand (TEU)) / MSK allocation (TEU)"
    }, {
        label: "Head Haul (% to Nominal)",
        details: "",
        value: "((Captura Intake (TEU)-MSK allocation (TEU))+MSK Dry Demand (TEU)+(MSK Reefer Demand (FFE)*2)) / Nominal Capacity"
    },
    {
        label: "MSK Year Allocation (TEU)",
        details: "",
        value: "Round Trip MSK allocation (TEU) * ( 52 / Round Trip (Days)/7))",//doubt in formula 42 in excel
    },
    {
        label: "MSK Year demand (TEU)",
        details: "",
        value: "Round Trip Demand (TEU) * ( 52 / Round Trip (Days)/7))",//doubt in formula 43 in excel
    },
    {
        label: "MtCo2",
        details: "Retrieved from Scdeule Planner",
        value: ""
    },
    {
        label: "Nominal Capacity",
        details: "Data retrieved from champs",
        value: ""
    },
    {
        label: "Nopad ($/Yr)",
        details: "",
        value: "((MSK Dry Demand (TEU) * (Dry CY (FFE) / 2) + MSK Reefer Demand (FFE) * Reefer CY (FFE) + MSK Dry Demand (TEU) * (Dry CY (FFE) /2) + MSK Reefer Demand (FFE) * Reefer CY (FFE)) * 52 / (Round Trip (Days) / 7)) - Yearly Cost ($)"
    },
    {
        label: "One Off ($)",
        details: "Manual input",
        value: ""
    },
    {
        label: "Round Trip (NM)",
        details: "Data retrieved from Data retrieved from schedule planner",
        value: ""
    },
    {
        label: "Round Trip (Days)",
        details: "Data retrieved from schedule planner",
        value: ""
    },
    {
        label: "Round Trip CONS (MT) ",
        details: "Data retrieved from schedule planner",
        value: ""
    },
    {
        label: "Round Trip CONS ($) ",
        details: " Sum of Bunker price * Bunker Consumption. Bunker ports are retrieved from champs and prices are retrieved from bops.",
        value: ""
    },
    {
        label: "Round Trip TC ($)",
        details: "",
        value: "TCD ($) * Round Trip (Days)"
    },
    {
        label: "Round Trip PEX ($) ",
        details: " Port expense",
        value: ""
    },
    //check this
    {
        label: "Round Trip COST ($) ",
        details: "Cosumption (USD) + (R/T days * TCD) + PEX",
        value: ""
    },
    {
        label: "Round Trip MSK allocation (TEU)",
        details: "",
        value: "MSK allocation (TEU) + MSK allocation (TEU)"
    },
    {
        label: "Round Trip Demand (TEU)",
        details: "",
        value: "(MSK Dry Demand (TEU) + MSK Dry Demand (TEU)) + (MSK Reefer Demand (FFE) + MSK Reefer Demand (FFE)) * 2"
    },
    {
        label: "Slot Cost",
        details: "",
        value: "Yearly Cost ($) / MSK Year Allocation (TEU)"
    },
    {
        label: "TCD ($)",
        details: "Manual input",
        value: ""
    },
    {
        label: "Unit Cost",
        details: "",
        value: "Yearly Cost ($)/MSK Year demand (TEU)"
    },
    {
        label: "Vessel Name",
        details: "Data retrieved from schedule planner",
        value: ""
    },
    {
        label: "Yearly Cost ($)",
        details: "",
        value: "((52 / (Round Trip (Days) / 7 )) * Round Trip COST ($) ) + One Off ($) + (TCD ($) * 365)"
    },
]