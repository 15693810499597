// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-section {
  height: 50px;
  background: var(--color-white);
  padding: 10px;
  box-shadow: 0px 12px 14px 2px rgba(0, 0, 0, 0.031372549);
  border-bottom: solid 1px var(--color-light-gray);
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 999;
}
.header-section .it-help a {
  color: var(--color-maersk-blue);
  font-weight: 500;
  text-decoration: none;
  margin: 0px 10px 0px 5px;
}
.header-section .timezone {
  color: var(--color-text-grey);
}
.header-section .user-info {
  margin-top: -3px;
}`, "",{"version":3,"sources":["webpack://./src/Layouts/Header/Header.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,8BAAA;EACA,aAAA;EACA,wDAAA;EACA,gDAAA;EACA,eAAA;EACA,WAAA;EACA,QAAA;EACA,YAAA;AACF;AAEI;EACE,+BAAA;EACA,gBAAA;EACA,qBAAA;EACA,wBAAA;AAAN;AAIE;EACE,6BAAA;AAFJ;AAIE;EACE,gBAAA;AAFJ","sourcesContent":[".header-section {\n  height: 50px;\n  background: var(--color-white);\n  padding: 10px;\n  box-shadow: 0px 12px 14px 2px #00000008;\n  border-bottom: solid 1px var(--color-light-gray);\n  position: fixed;\n  width: 100%;\n  top: 0px;\n  z-index: 999;\n\n  .it-help {\n    a {\n      color: var(--color-maersk-blue);\n      font-weight: 500;\n      text-decoration: none;\n      margin: 0px 10px 0px 5px;\n    }\n  }\n\n  .timezone {\n    color: var(--color-text-grey);\n  }\n  .user-info{\n    margin-top: -3px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
