//React
import React from "react";
// Anchor Components
import { TableV2 } from "@anchor/react-components/dist/lib/components";
// Styles
import './DisplayDelta.scss';
import { VesselPerformanceComparisonModel } from "./DisplayDeltaModel";

interface DisplayTableDetailsProps {
  data: VesselPerformanceComparisonModel[];
}

//Function to calculate the total of the baseline and proposal
const calculateTotals = (data, columns) => {
  if (!columns?.length) return {};
  const totals = {};
  columns?.forEach(column => {
    if (column?.accessorKey) {
      totals[column.accessorKey] = data.reduce((sum, row) => sum + (row[column.accessorKey] || 0), 0);
    }
  });
  return totals;
};

const DisplayDeltaTableDetails = ({ data }: DisplayTableDetailsProps) => {

  const getCellValue = (getValue, row) => {
    const result = Math.round(getValue() * 100) / 100;
    return <div className={`cell-value ${row.original.vesselName == 'Total' && 'total-value'}`} title={result.toLocaleString()}>{result.toLocaleString()}</div>;
  }
  const deltaValueDisplay = (deltaValue) => {
    const result = Math.round(deltaValue * 100) / 100;
    return <div className="delta-value">{result.toLocaleString()}</div>
  }
  const column = [
    {
      id: "vesselName",
      header: "Vessel Name",
      accessorKey: "vesselName",
      alignData: "center",
      cell: ({ getValue, row }) => (
        <div className={`${row.original.vesselName == 'Total' && 'total-value'}`}>{getValue()}</div>
      ),
      footer: () => <div className="delta-value">Delta</div>,
    },
    {
      id: "BaseLine",
      header: () => <div className="baseline-header">BaseLine</div>,
      accessorKey: "baseline",
      alignData: "center",
      size: 150,
      enableSorting: false,
      cell: ({ getValue, row }) => (
        getCellValue(getValue, row)
      ),
      footer: () => <div className="delta-value">{deltaValueDisplay(deltaValue)}</div>,
    },
    {
      id: "Proposal",
      header: () => <div className="proposal-header">Proposal</div>,
      accessorKey: "proposal",
      alignData: "center",
      enableSorting: false,
      cell: ({ getValue, row }) => (
        getCellValue(getValue, row)
      ),
      footer: " "
    }
  ]

  //Variable to store the total of the baseline and proposal
  const totals = calculateTotals(data, column);
  const totalRow: any = column?.reduce((row, column) => {
    row[column?.accessorKey] = column?.accessorKey === 'vesselName' ? 'Total' : totals[column?.accessorKey];
    return row;
  }, {});
  const deltaValue = totalRow?.proposal - totalRow?.baseline;
  //Adding the total row to the data
  const tableData = [...data, totalRow];
  return (
    <div className="delta-table">
      <TableV2
        columnData={column}
        defaultData={tableData}
        stickyHeader={true}
        maxHeight={305}
        maxWidth={700}
      />
    </div>
  );
}
export default DisplayDeltaTableDetails;