import React from "react";
import DataTable from "react-data-table-component";
import { Vessel, Option } from "../model";
interface TableProps {
  originalJSON?: any;
}
const PermutationTable = ({ originalJSON }: TableProps) => {
  const formatTheHeaderName = (headerName: string) => {
    const capitalizeHeaderName =
      headerName.charAt(0).toUpperCase() + headerName.slice(1);
    const regexPattern = /[^0-9](?=[0-9])/g;
    return capitalizeHeaderName.replace(regexPattern, "$& ");
  };

function extractVesselNames(originalData: any[]): string[] {
    const uniqueVesselNames: Set<string> = new Set();
    originalData?.forEach(option => {
      option.vessels?.forEach((vessel:any) => {
        uniqueVesselNames.add(vessel.vesselName);
      });
    });
    return Array.from(uniqueVesselNames);
  }
  const convertColumns = (jsonData: any) => {
    if (jsonData?.length > 0) {
      const columns: any = [
        {
          name: "Options",
          selector: (row: any) => row.optionName,
        },
      ];
      const vesselNames: string[] = extractVesselNames(jsonData);
      vesselNames?.forEach((option: any) => {
        columns.push({
          name: formatTheHeaderName(option),
          selector: (row: string) => row[option] === "" ? "-" : row[option],
        });
      });
      columns.push({
        name: "NOPAT",
        selector: (row: any) => row.nopat,
      });

      return columns;
    }
  };
  const covertedColumsdata = convertColumns(originalJSON);
  function convertJson(originalData: any[]) {
    const allVessels: string[] = originalData?.reduce<string[]>((acc, option) => {
      option?.vessels?.forEach((vessel:Vessel) => {
        if (!acc.includes(vessel.vesselName)) {
          acc.push(vessel.vesselName);
        }
      });
      return acc;
    }, []);
  
    return originalData?.map(option => {
      const convertedOption: Option = {
        optionName: option.optionName,
        nopat: option.nopat
      };
      allVessels?.forEach(vesselName => {
        const vessel = option.vessels.find((v:Vessel) => v.vesselName === vesselName);
        convertedOption[vesselName] = vessel ? vessel.service : '';
      });
  
      return convertedOption;
    });
  }
  const convertedDatanew = convertJson(originalJSON);
  return (
    <div className="tableData table-section">
      <DataTable columns={covertedColumsdata} data={convertedDatanew} />
    </div>
  );
};
export default PermutationTable;
