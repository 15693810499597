import { getToken } from "../utils/token";
import { protectedResources } from "../authConfig";
const getStatus = (response: any) => {
  if (response.status === 200) {
    return response.json();
  } else if (response.status === 403) {
    window.location.href = "/AccessDenied"
  }
}
export const getAPI = async (url: any, responseType?: string) => {
  try {
    const accessToken = await getToken(protectedResources.ned.scopes.read)
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    headers.append("Consumer-Key", protectedResources.ned.consumerkey);
    const options = {
      method: "GET",
      headers: headers,
    };
    return fetch(protectedResources.ned.baseurl + `${url}`, options)
      .then((response: any) => {      
        return getStatus(response)
      })
      .then((data: any) => data)
      .catch((error) => console.log(error));
  } catch (error) {
    console.log("error is ", error);
  }
};

export const postAPI = async (url: any, postData?: any) => {
    try {
      const accessToken = await getToken(protectedResources.ned.scopes.read)
      const bearer = `Bearer ${accessToken}`;
  
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearer,
          "Consumer-Key":protectedResources.ned.consumerkey,
        },
        body: JSON.stringify(postData),
      };
  
      return fetch(
        protectedResources.ned.baseurl + `${url}`,
        options
      )
        .then((response) => response.json())
        .catch((error) => console.log(error));
    } catch (error) {
      console.log("error is ", error);
    }
  };
  export const putAPI = async (url: any, postData?: any) => {
    try {
      const accessToken = await getToken(protectedResources.ned.scopes.read)
      const bearer = `Bearer ${accessToken}`;
  
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearer,
          "Consumer-Key":protectedResources.ned.consumerkey,
        },
        body: JSON.stringify(postData),
      };
  
      return fetch(
        protectedResources.ned.baseurl + `${url}`,
        options
      )
        .then((response) => response.json())
        .catch((error) => console.log(error));
    } catch (error) {
      console.log("error is ", error);
    }
  };

  export const uploadFileAPI = async (url: any, formData: any) => {
    try {
      const accessToken = await getToken(protectedResources.ned.scopes.read);
      const bearer = `Bearer ${accessToken}`;

      const options = {
        method: "POST",
        headers: {
          Authorization: bearer,
          "Consumer-Key":protectedResources.ned.consumerkey,
        },
        body: formData,
      };

      return fetch(protectedResources.ned.baseurl + `${url}`, options)
        .then((response) => typeof(response) == "boolean" ? response : response.json())
        .catch((error) => console.log(error));
    } catch (error) {
      console.log("error is ", error);
    }
  };
  export const deleteAPI = async (url: any) => {
    try {
      const accessToken = await getToken(protectedResources.ned.scopes.read)
      const bearer = `Bearer ${accessToken}`;
  
      const options = {
        method: "DELETE",
        headers: {
          Authorization: bearer,
          "Consumer-Key":protectedResources.ned.consumerkey,
        },
      };
  
      return fetch(
        protectedResources.ned.baseurl + `${url}`,
        options
      )
        .then((response) => response.json())
        .catch((error) => console.log(error));
    } catch (error) {
      console.log("error is ", error);
    }
  }