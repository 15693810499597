import { Icon } from "@anchor/react-components/dist/lib/components";
import Card from "@anchor/react-components/dist/lib/components/Card";

const SimulationBanner = ({ runDetails }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`.toUpperCase();
  };

  const formattedDate = formatDate(runDetails?.createdOn);

  return (
    <div className="mb-3 bg-white">
      <Card
        body=""
        fit="small"
        footer={
          <>
            <div>
              <span>Created by: {runDetails?.createdBy}</span>
              <span className="mx-3">Created on: {formattedDate}</span>
              <span className="mx-3">Run ID: {runDetails?.runId}</span>
              <span className="mx-3">
                Request Name: {runDetails?.requestName}
              </span>
            </div>
          </>
        }
        heading={
          <div className="d-flex align-items-center">
            <span style={{lineHeight: "normal"}}>
              <Icon size="24" name="vessel-front" />
            </span>
            <span className="mx-2">{runDetails?.vesselName}</span>
          </div>
        }
        orientation="horizontal"
        subHeading=""
        variant="bordered"
      />
    </div>
  );
};

export default SimulationBanner;
