// React
import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

// Providers
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import AppProvider from "./contextProviders/AppContextProvider";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { Toast } from "@anchor/react-components";
// Components
import SideNav from "./Layouts/SideNav/SideNav";
import Header from "./Layouts/Header/Header";

// Pages
import DtcoOverviewPage from "./pages/DtcoOverviewPage/DtcoOverviewPage";
import RequestPage from "./pages/RequestPage/RequestPage";
import FBPvalidation from "./pages/FBPValidation/FBPValidation";
import AccessDenied from "./Components/AccessDenied/AccessDenied";
import SimulationRequest from "./pages/SimulationRequest/SimulationRequest";
import SimulationRequestResult from "./pages/SimulationRequest/Components/SimulationRequestResult/SimulationRequestResult";

// Styles
import "./App.scss";
import "./Styles/Global.scss";
import "./Styles/mediaQuery.scss";
import DeploymentValidation from "./pages/FBPValidation/DeploymentValidation";
import ProposalValidation from "./pages/FBPValidation/ProposalValidation";
import { getUserRole } from "./utils/token";

const Home = React.lazy(() => import("./pages/Home"));

function App({ msalInstance }: { msalInstance: any }) {
  const [isSideNav, setIsSideNav] = useState(false);
  const [userRole, setUserRole] = useState<string>("");
  const sideNavClicck = (val: any) => {
    setIsSideNav(!val);
  };
  const authRequest = {
    ...loginRequest,
  };
  const handleMSALMethods = async () => {
    const redirectResponse = await msalInstance.handleRedirectPromise();
    if (redirectResponse) {
      getUserRole().then((role) => {
        setUserRole(role);
        sessionStorage.setItem("userRole", role);
      });
    }
  }

  useEffect(() => {
    handleMSALMethods() 
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      >
        <AppProvider>
          <div className="main">
            <Suspense fallback={<div>Loading...</div>}>
              {
                userRole === "noAccess" ? (
                  <AccessDenied />
                ) : (
                  <>
                    <div className="header">
                      <Header />
                    </div>
                    <div className="side-nav">
                      <SideNav onSideClick={sideNavClicck} />
                    </div>
                    <div className={`main-body ${isSideNav && "Stechactive"}`}>
                      <Toast
                        options={{
                          autoClose: 4000,
                          closeOnClick: true,
                          draggable: true,
                          hideProgressBar: false,
                          pauseOnHover: true,
                          progress: undefined,
                          type: "info",
                        }}
                      />
                      <Routes>
                        <Route path="/AccessDenied" element={<AccessDenied />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/SimulationRequest" element={<SimulationRequest />} />
                        <Route path="/SimulationRequest/SimulationRequestResult/:requestId" element={<SimulationRequestResult />} />

                        <Route path="/RequestPage" element={<RequestPage />} />
                        <Route path="/RequestPage/FBPvalidation" element={<FBPvalidation />} />
                        <Route
                          path="/RequestPage/DtcoOverviewPage/:requestId"
                          element={<DtcoOverviewPage />}
                        />
                        <Route
                          path="/RequestPage/ProposalValidationScreen/:id"
                          element={<ProposalValidation />}
                        />
                        <Route
                          path="/RequestPage/DeploymentValidation"
                          element={<DeploymentValidation />}
                        />
                      </Routes>
                    </div>
                  </>
                )
              }
            </Suspense>
          </div>
        </AppProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
