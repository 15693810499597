import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Anchor Components
import TableV2 from "@anchor/react-components/dist/lib/components/TableV2";
import {
  Button,
  Input,
  Drawer,
  toastEmitter,
  Checkbox,
} from "@anchor/react-components/dist/lib/components";

// styles
import "./SimulationRequestResult.scss";

// Components
import SimulationDataFeedback from "../SimulationResultFeedback/SimulationDataFeedback";
import SimulationDataAddFeedbackComponent from "../SimulationDataAddFeedback/SimulationDataAddFeedback";
import SimulationBanner from "../SimulationBanner/SimulationBanner";
import PageLoader from "../../../../Components/PageLoader/PageLoader";
import SimulationRequestHistoryTable from "../SimulationRequestHistoryTable/SimulationRequestHistoryTable";

// Services
import { getAPI, postAPI } from "../../../../Services/APIservices";

// Model and Constants
import { SimulationData } from "../../Model";
import { excelDownload } from "../../../../utils/excelDownload";
import { AppString } from "../../../../utils/AppString";

const SimulationRequestResult = () => {
  const { requestId } = useParams<{ requestId: string }>();
  const [isOpen, setIsOpen] = useState(false);
  const [openResultLogModal, setOpenResultLogModal] = useState(false);
  const [simulationData, setSimulationData] = useState<SimulationData>();
  const [filterData, setFilterData] = useState<SimulationData>() as any;
  const [checkedState, setCheckedState] = useState({});
  const [isAnyChecked, setIsAnyChecked] = useState(false);
  const [selectedServiceRank, setSelectedServiceRank] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [serviceCodes, setServiceCodes] = useState<string[]>([]);

  const getSimulationData = async () => {
    const url = `simulation-api/Simulation/GetRecomendationsAsync/${requestId}`;
    await getAPI(url)
      .then(async (response) => {
        const res = response.solutionData.map((item) => {
          if (item.vesselListIn === null) {
            item.vesselListIn = '-';
          }
          if (item.serviceIn === null) {
            item.serviceIn = '-';
          }
          if (item.vesselListOut === null) {
            item.vesselListOut = '-';
          }
          if (item.serviceOut === null) {
            item.serviceOut = '-';
          }
          return item;
        });

        const formattedResponse = {
          ...response,
          solutionData: res,
        }
        setSimulationData(formattedResponse);
        setFilterData(formattedResponse);
      })
      .catch((error) => {
        toastEmitter(
          {
            className: "customClassName",
            title: "Error occured while fetching simulation results.",
          },
          { type: "error" }
        );
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getSimulationData();
      localStorage.removeItem(`simulationCommentsHistory`);
    };
    fetchData();
  }, []);

  const CustomVesselTemplate = ({ row, type }) => {
    const value = row.original[type];
    return (
      <>
        <div>{value[0] ? value[0] : "-"}</div>
        <div className="mb-3">{value[1] ? value[1] : "-"}</div>
      </>
    );
  };

  const CustomServiceTemplate = ({ row, type }) => {
    const value = row.original[type];
    return <div>{value}</div>;
  };

  const CustomDeltaTemplate = ({ row, type }) => {
    const value = row.original[type];
    return <div>{value}</div>;
  };

  const getBackgroundClass = (value) => {
    if (value === 0) return "zero";
    return value < 0 ? "negative" : "positive";
  };

  const handleCheckboxChange = (solutionRank, serviceCodes) => (event) => {
    setSelectedServiceRank([]);
    setServiceCodes([]);
    setSelectedServiceRank([solutionRank]);
    setServiceCodes(serviceCodes);
    const isChecked = event.target.checked;
    setCheckedState({
      ...checkedState,
      [event.target.id]: event.target.checked,
    });
    if (isChecked) {
      setIsAnyChecked(true);
    } else {
      const isStillAnyChecked = Object.values(checkedState).some(
        (value) => value
      );
      setIsAnyChecked(!isStillAnyChecked);
    }
  };

  const hadleCreateNewDTCO = async (selectedSolution) => {
    if (selectedServiceRank[0] !== 1 && selectedSolution === "topSolution") {
      setOpenResultLogModal(true);
      return;
    }

    setIsLoading(true);
    const url = `/dtco-api/dtcosummary/CreateRequestIdAndDtcoProposalAsync`;
    const payload = {
      simulationRequestId: requestId,
      serviceRanks: selectedServiceRank,
      vesselName: simulationData?.simulationRequestResponse?.vesselName,
      serviceCodes: serviceCodes,
    };
    postAPI(url, payload)
      .then((response) => {
        toastEmitter(
          {
            className: "customClassName",
            title: "DTCO proposal created successfully",
          },
          { type: "success" }
        );
        setIsLoading(false);
        window.location.href = `/RequestPage/DtcoOverviewPage/${response.requestId}`;
      })
      .catch((error) => {
        setIsLoading(false);
        toastEmitter(
          {
            className: "customClassName",
            title: "Error occured while creating DTCO proposal.",
          },
          { type: "error" }
        );
        console.error("An error occurred:", error);
      });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const searchForVessel = (e) => {
    const value = e.target.value;
    const filteredData = simulationData?.solutionData.filter((item) => {
      return (
        item.vesselListIn[0]?.toLowerCase().includes(value.toLowerCase()) ||
        item.vesselListIn[1]?.toLowerCase().includes(value.toLowerCase()) ||
        item.vesselListOut[0]?.toLowerCase().includes(value.toLowerCase()) ||
        item.vesselListOut[1]?.toLowerCase().includes(value.toLowerCase())
      );
    });

    setFilterData({
      ...simulationData,
      solutionData: filteredData,
    });
    setCurrentPage(1);
  }

  const paginatedData = filterData?.solutionData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const columns = [
    {
      id: "serviceOut",
      accessorKey: "serviceOut",
      header: "",
      alignData: "center",
      enableSorting: false,
      cell: ({ row }) => (
        <div className="m-2">
          <Checkbox
            id={"solution" + row.original.solutionRank}
            name={"solution" + row.original.solutionRank}
            fit="medium"
            label=""
            checked={!!checkedState["solution" + row.original.solutionRank]}
            disabled={
              isAnyChecked &&
              !checkedState["solution" + row.original.solutionRank]
            }
            onChange={handleCheckboxChange(row.original.solutionRank, [
              row.original.serviceIn,
              row.original.serviceOut,
            ])}
          />
        </div>
      ),
    },
    {
      accessorKey: "solutionRank",
      header: "Rank",
      id: "solutionRank",
      enableSorting: false,
      alignData: "center",
    },
    {
      accessorKey: "serviceIn",
      header: "Service",
      id: "serviceIn",
      enableSorting: false,
      cell: ({ row }) => (
        <div>
          <CustomServiceTemplate row={row} type="serviceIn" />
          <CustomServiceTemplate row={row} type="serviceOut" />
          <div style={{ height: "15px" }}></div>
        </div>
      ),
    },
    {
      accessorKey: "vesselListIn",
      header: "Vessel(s) In",
      id: "vesselListIn",
      enableSorting: false,
      cell: ({ row }) => <CustomVesselTemplate row={row} type="vesselListIn" />,
    },
    {
      accessorKey: "vesselListOut",
      header: "Vessel(s) Out",
      id: "vesselListOut",
      enableSorting: false,
      cell: ({ row }) => (
        <CustomVesselTemplate row={row} type="vesselListOut" />
      ),
    },
    {
      accessorKey: "deltaCY",
      columns: [
        {
          accessorKey: "deltaCyInMin",
          header: "Min.",
          id: "deltaCyInMin",
          alignData: "center",
          enableSorting: false,
          cell: ({ row }) => (
            <div className="delta-data">
              <div className="delta-data-values">
                <CustomDeltaTemplate row={row} type="deltaCyInMin" />
              </div>
              <div className="delta-data-values">
                <CustomDeltaTemplate row={row} type="deltaCyOutMin" />
              </div>
              <div
                className={`${getBackgroundClass(
                  (
                    row.original.deltaCyInMin + row.original.deltaCyOutMin
                  ).toFixed(2)
                )} fw-semibold total-delta`}
              >
                {simulationData &&
                  (
                    row.original.deltaCyInMin + row.original.deltaCyOutMin
                  ).toFixed(2)}
              </div>
            </div>
          ),
        },
        {
          accessorKey: "deltaCyInAvg",
          header: "Avg.",
          id: "deltaCyInAvg",
          alignData: "center",
          enableSorting: false,
          cell: ({ row }) => (
            <div className="delta-data">
              <div className="delta-data-values">
                <CustomDeltaTemplate row={row} type="deltaCyInAvg" />
              </div>
              <div className="delta-data-values">
                <CustomDeltaTemplate row={row} type="deltaCyOutAvg" />
              </div>
              <div
                className={`${getBackgroundClass(
                  (
                    row.original.deltaCyInAvg + row.original.deltaCyOutAvg
                  ).toFixed(2)
                )} fw-semibold total-delta`}
              >
                {(
                  row.original.deltaCyInAvg + row.original.deltaCyOutAvg
                ).toFixed(2)}
              </div>
            </div>
          ),
        },
        {
          accessorKey: "deltaCyInMax",
          header: "Max.",
          id: "deltaCyInMax",
          alignData: "center",
          enableSorting: false,
          cell: ({ row }) => (
            <div className="delta-data">
              <div className="delta-data-values">
                <CustomDeltaTemplate row={row} type="deltaCyInMax" />
              </div>
              <div className="delta-data-values">
                <CustomDeltaTemplate row={row} type="deltaCyOutMax" />
              </div>
              <div
                className={`${getBackgroundClass(
                  (
                    row.original.deltaCyOutMax + row.original.deltaCyInMax
                  ).toFixed(2)
                )} fw-semibold total-delta`}
              >
                {(
                  row.original.deltaCyInMax + row.original.deltaCyOutMax
                ).toFixed(2)}
              </div>
            </div>
          ),
        },
      ],
      enableSorting: false,
      header: "Delta CY",
      id: "deltaCY",
    },
    {
      accessorKey: "deltaFuelIn",
      header: "Delta Fuel",
      id: "deltaFuelIn",
      alignData: "center",
      enableSorting: false,

      maxWidth: "100px",
      cell: ({ row }) => (
        <div className="delta-data">
          <div className="delta-data-values">
            <CustomDeltaTemplate row={row} type="deltaFuelIn" />
          </div>
          <div className="delta-data-values">
            <CustomDeltaTemplate row={row} type="deltaFuelOut" />
          </div>
          <div
            className={`${getBackgroundClass(
              (row.original.deltaFuelOut + row.original.deltaFuelIn).toFixed(2)
            )} fw-semibold total-delta`}
          >
            {(row.original.deltaFuelIn + row.original.deltaFuelOut).toFixed(2)}
          </div>
        </div>
      ),
    },
    {
      accessorKey: "deltaEBIT",
      columns: [
        {
          accessorKey: "deltaEbitInMin",
          header: "Min.",
          id: "deltaEbitInMin",
          alignData: "center",
          enableSorting: false,
          cell: ({ row }) => (
            <div className="delta-data">
              <div className="delta-data-values">
                <CustomDeltaTemplate row={row} type="deltaEbitInMin" />
              </div>
              <div className="delta-data-values">
                <CustomDeltaTemplate row={row} type="deltaEbitOutMin" />
              </div>
              <div
                className={`${getBackgroundClass(
                  (
                    row.original.deltaEbitOutMin + row.original.deltaEbitInMin
                  ).toFixed(2)
                )} fw-semibold total-delta`}
              >
                {(
                  row.original.deltaEbitInMin + row.original.deltaEbitOutMin
                ).toFixed(2)}
              </div>
            </div>
          ),
        },
        {
          accessorKey: "deltaEbitInAvg",
          header: "Avg.",
          id: "deltaEbitInAvg",
          alignData: "center",
          enableSorting: false,
          cell: ({ row }) => (
            <div className="delta-data">
              <div className="delta-data-values">
                <CustomDeltaTemplate row={row} type="deltaEbitInAvg" />
              </div>
              <div className="delta-data-values">
                <CustomDeltaTemplate row={row} type="deltaEbitOutMin" />
              </div>
              <div
                className={`${getBackgroundClass(
                  (
                    row.original.deltaEbitOutAvg + row.original.deltaEbitInAvg
                  ).toFixed(2)
                )} fw-semibold total-delta`}
              >
                {(
                  row.original.deltaEbitInAvg + row.original.deltaEbitOutAvg
                ).toFixed(2)}
              </div>
            </div>
          ),
        },
        {
          accessorKey: "deltaEbitInMax",
          header: "Max.",
          id: "deltaEbitInMax",
          alignData: "center",
          enableSorting: false,
          cell: ({ row }) => (
            <div className="delta-data">
              <div className="delta-data-values">
                <CustomDeltaTemplate row={row} type="deltaEbitInMax" />
              </div>
              <div className="delta-data-values">
                <CustomDeltaTemplate row={row} type="deltaEbitOutMax" />
              </div>
              <div
                className={`${getBackgroundClass(
                  (
                    row.original.deltaEbitOutMax + row.original.deltaEbitInMax
                  ).toFixed(2)
                )} fw-semibold total-delta`}
              >
                {(
                  row.original.deltaEbitOutMax + row.original.deltaEbitInMax
                ).toFixed(2)}
              </div>
            </div>
          ),
        },
      ],
      enableSorting: false,
      header: "Delta EBIT",
      id: "deltaEBIT",
    },
  ];

  const downloadExcel = () => {
    const dataSource = simulationData?.solutionData?.map((e: any) => {
      return {
        solutionRank: e.solutionRank,
        serviceIn: e.serviceIn,
        serviceOut: e.serviceOut,
        vesselListIn: e.vesselListIn.join(" - "),
        vesselListOut: e.vesselListOut.join(" - "),
        deltaCyInMin: e.deltaCyInMin,
        deltaCyInAvg: e.deltaCyInAvg,
        deltaCyInMax: e.deltaCyInMax,
        deltaEbitInMin: e.deltaEbitInMin,
        deltaEbitInAvg: e.deltaEbitInAvg,
        deltaEbitInMax: e.deltaEbitInMax,
        deltaCyOutMin: e.deltaCyOutMin,
        deltaCyOutAvg: e.deltaCyOutAvg,
        deltaCyOutMax: e.deltaCyOutMax,
        deltaEbitOutMin: e.deltaEbitOutMin,
        deltaEbitOutAvg: e.deltaEbitOutAvg,
        deltaEbitOutMax: e.deltaEbitOutMax,
        deltaFuelIn: e.deltaFuelIn,
        deltaFuelOut: e.deltaFuelOut,
      };
    });

    const header = [
      "Solution Rank",
      "Service In",
      "Service Out",
      "Vessel List In",
      "Vessel List Out",
      "Delta CY In Min",
      "Delta CY In Avg",
      "Delta CY In Max",
      "Delta EBIT In Min",
      "Delta EBIT In Avg",
      "Delta EBIT In Max",
      "Delta CY Out Min",
      "Delta CY Out Avg",
      "Delta CY Out Max",
      "Delta EBIT Out Min",
      "Delta EBIT Out Avg",
      "Delta EBIT Out Max",
      "Delta Fuel In",
      "Delta Fuel Out",
    ];

    excelDownload("Request_Data", dataSource, header);
  };

  if (!simulationData) {
    return <PageLoader isLoader={true} />;
  }
  return (
    <div className="simulation-section p-3">
      <div className="align-items-center d-flex justify-content-between">
        <h1 className="header-text">Simulation Result</h1>
        <div className="d-flex gap-2">
          <Button
            id="primary"
            justifyItems="center"
            label="Create new DTCO proposal"
            name="primary"
            fit="small"
            title="Proceed"
            variant="filled"
            onClick={() => hadleCreateNewDTCO("topSolution")}
            disabled={!isAnyChecked}
            loading={isLoading}
          />
        </div>
      </div>
      <SimulationBanner runDetails={simulationData.simulationRequestResponse} />
      {simulationData?.solutionData?.length ? (
        <>
          <div className="mb-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <Input
                  clearButton
                  fit="small"
                  icon="magnifying-glass"
                  iconPosition="left"
                  id="searchInput"
                  placeholder="Search for Vessel Name"
                  variant="default"
                  width={100}
                  onChange={searchForVessel}
                />
              </div>
              <div className="d-flex">
                <Button
                  className="mx-2"
                  variant="outlined"
                  label="Simulation Result Log"
                  onClick={() => setIsOpen(true)}
                  fit="small"
                />
                <Button
                  appearance="default"
                  id="export-excel"
                  icon="tray-arrow-down"
                  justifyItems="center"
                  label="Export"
                  name="Excel"
                  fit="small"
                  onClick={downloadExcel}
                  iconPosition="right"
                  variant="outlined"
                />
              </div>
            </div>
          </div>
          <div className="simulation-result-table">
            <TableV2
              columnData={columns}
              defaultData={paginatedData || []}
              gridLine="both"
              fit="small"
              currentPage={currentPage}
              onPageChange={handlePageChange}
              defaultSelectedValue={itemsPerPage}
              totalPages={Math.ceil(
                simulationData?.solutionData.length / itemsPerPage
              )}
              hidePageSizeOptions={true}
              showPagination
            />
          </div>

          <div className="mt-4">
            <h5>{AppString.simulationProposalCreatedTitle}</h5>
            <SimulationRequestHistoryTable
              simulationRequestHistory={
                simulationData?.simulationDtcoRequestHistory
              }
            />
          </div>
          <div>
            <div className="w-50">
              <Drawer
                className="drawer"
                direction="right"
                onClose={() => setIsOpen(false)}
                open={isOpen}
                zindex={9999}
              >
                <SimulationDataFeedback
                  requestId={requestId}
                  isDrawerOpen={isOpen}
                />
              </Drawer>
            </div>
          </div>
          <div>
            <SimulationDataAddFeedbackComponent
              showCommentsModal={openResultLogModal}
              setShowCommentsModal={setOpenResultLogModal}
              selectedServiceRank={selectedServiceRank}
              requestId={requestId}
              onCreateNewDTCO={() => hadleCreateNewDTCO("otherSolution")}
            />
          </div>
        </>
      ) : (
        <div className="no-data">
          <h4>No data available</h4>
        </div>
      )}
    </div>
  );
};

export default SimulationRequestResult;
