import { useEffect, useState } from "react";
import {
    Button,
    Modal,
    ReactSelect,
    TextArea,
} from "@anchor/react-components/dist/lib/components";
import { getAPI } from "../../../Services/APIservices";
import { AppString } from "../../../utils/AppString";

interface IPROPS {
    isOpen?: boolean;
    onClose?: any;
    submitFun?: any;
}

const RejectProposal = ({
    isOpen,
    onClose,
    submitFun
}: IPROPS) => {
    const [reasonType, setReasonType] = useState({
        label: "",
        value: ""
    });
    const [rejectComment, setRejectComment] = useState("");
    const [rejectUserType, setRejectUserType] = useState([]);
    const [errors, setErrors] = useState(false);
    const handleSubmit = () => {
        if (reasonType?.label === "" || reasonType === null || reasonType === undefined) {
            setErrors(true);
        }
        else {
            const payload = {
                isAccepted: false,
                rejectionComment: rejectComment || "",
                rejectionTypeId: reasonType?.value
            }
          
            submitFun(payload);
            setRejectComment("");
            setReasonType({
                label: "",
                value: ""
            });
            onClose();
        }
    }
    const getRejectUserType = async () => {
        const response = await getAPI(`dtco-api/dtcosummary/GetAllRejectionTypes`);
        setRejectUserType(response);
    }

    useEffect(() => {
        getRejectUserType();
    }, []);
    
    const handleDropdown = (value: any) => {
        if (value?.label === "" || value === null || value === undefined) {
            setErrors(true);
        } else {
            setErrors(false);
            setReasonType(value);
        }
    }
    const handleClose = () => {
        onClose();
        setRejectComment("");
        setReasonType({
            label: "",
            value: ""
        });
        setErrors(false);
    }
    return (
        <Modal
            open={isOpen}
            width="480px"
            height="auto"
            showCloseIcon={false}
            actions={{
                primaryAction: (
                    <Button
                        label="Reject"
                        fit="small"
                        type="submit"
                        onClick={handleSubmit}
                    />
                ),
                secondaryAction: (
                    <Button
                        label="Dismiss"
                        fit="small"
                        onClick={handleClose}
                        variant="outlined"
                    />
                ),
            }}
        >
            <div className="accept-reject-proposal">
                <div className="modal-headerSection">
                    <h1 className="modal-header">Reject Request</h1>
                </div>
                <h4 className="sub-header-text mb-3">Add  reason for escalation</h4>
                <div className="row">
                    <div className="col-md-12 mb-2">
                        <ReactSelect
                            className="react-select"
                            closeMenuOnSelect
                            label="Reason"
                            customNoOptionsMessage={<p>Not a valid option</p>}
                            fit="small"
                            hintPosition="left"
                            id="reason"
                            isClearable
                            isSearchable
                            name="reason"
                            onChange={(e) => {
                                handleDropdown(e);
                            }}
                            options={rejectUserType}
                            orientation="vertical"
                            suggestType="static"
                            variant="default"
                            width={100}
                            error={errors}
                            errorMessage={AppString.errorMsgForRequiredFields}
                            required
                        />
                    </div>
                    <div className="col-md-12 mb-2">
                        <TextArea
                            fit="small"
                            id="textInput"
                            label="Comment (optional)"
                            onChange={(e) => {
                                setRejectComment(e.target.value);
                            }}
                            rows={3}
                            maxLength={4000}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default RejectProposal;
