// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delta-data {
  text-align: center;
}
.delta-data-values {
  border-bottom: 1px solid #EDEDED;
}

.simulation-result-table tr > th:first-child,
.simulation-result-table tr > td:first-child {
  width: 28px !important;
}
.simulation-result-table tr > th:nth-child(2),
.simulation-result-table tr > td:nth-child(2) {
  width: 30px !important;
}

.simulation-result-table tr > th:nth-child(2) {
  text-align: center !important;
}

.simulation-result-table tr > th:first-child {
  text-align: center;
}

.deltaFuelIn {
  width: 50px !important;
}

.solutionRank {
  width: 50%;
  text-align: center;
}

.deltaCyInMin,
.deltaCyInAvg,
.deltaCyInMax,
.deltaFuelIn,
.deltaEbitInMin,
.deltaEbitInAvg,
.deltaEbitInMax,
th.deltaCyInMin,
th.deltaCyInAvg,
th.deltaCyInMax,
th.deltaFuelIn,
th.deltaEbitInMin,
th.deltaEbitInAvg,
th.deltaEbitInMax {
  padding: 0 !important;
}

.positive {
  color: var(--color-green);
}

.negative {
  color: var(--color-red);
}

.zero {
  color: var(--color-black);
}

.notification {
  background-color: var(--color-white);
}

.total-delta {
  padding: 3px !important;
}

.bbPYkx {
  min-height: 50px !important;
}

.cuwWAy {
  margin: 10px 16px 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/SimulationRequest/Components/SimulationRequestResult/SimulationRequestResult.scss"],"names":[],"mappings":"AAKA;EACI,kBAAA;AAJJ;AAMI;EACI,gCAAA;AAJR;;AAUI;;EACI,sBAAA;AANR;AASI;;EACI,sBAAA;AANR;;AAUA;EACI,6BAAA;AAPJ;;AAUA;EACI,kBAAA;AAPJ;;AAUA;EACI,sBAAA;AAPJ;;AAUA;EAnCI,UAAA;EACA,kBAAA;AA6BJ;;AAUA;;;;;;;;;;;;;;EAcI,qBAAA;AAPJ;;AAUA;EACI,yBAAA;AAPJ;;AAUA;EACI,uBAAA;AAPJ;;AAUA;EACI,yBAAA;AAPJ;;AAUA;EACI,oCAAA;AAPJ;;AAUA;EACI,uBAAA;AAPJ;;AAUA;EACI,2BAAA;AAPJ;;AAUA;EACI,8BAAA;AAPJ","sourcesContent":["@mixin center-content {\n    width: 50%;\n    text-align: center;\n}\n\n.delta-data {\n    text-align: center;\n\n    &-values {\n        border-bottom: 1px solid #EDEDED;\n    }\n}\n\n.simulation-result-table tr>th,\n.simulation-result-table tr>td {\n    &:first-child {\n        width: 28px !important;\n    }\n\n    &:nth-child(2) {\n        width: 30px !important;\n    }\n}\n\n.simulation-result-table tr>th:nth-child(2) {\n    text-align: center !important;\n}\n\n.simulation-result-table tr>th:first-child {\n    text-align: center;\n}\n\n.deltaFuelIn {\n    width: 50px !important;\n}\n\n.solutionRank {\n    @include center-content;\n}\n\n\n.deltaCyInMin,\n.deltaCyInAvg,\n.deltaCyInMax,\n.deltaFuelIn,\n.deltaEbitInMin,\n.deltaEbitInAvg,\n.deltaEbitInMax,\nth.deltaCyInMin,\nth.deltaCyInAvg,\nth.deltaCyInMax,\nth.deltaFuelIn,\nth.deltaEbitInMin,\nth.deltaEbitInAvg,\nth.deltaEbitInMax {\n    padding: 0 !important;\n}\n\n.positive {\n    color: var(--color-green);\n}\n\n.negative {\n    color: var(--color-red);\n}\n\n.zero {\n    color: var(--color-black);\n}\n\n.notification {\n    background-color: var(--color-white);\n}\n\n.total-delta {\n    padding: 3px !important;\n}\n\n.bbPYkx {\n    min-height: 50px !important;\n}\n\n.cuwWAy {\n    margin: 10px 16px 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
