// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Anchor Components
import { Button, Modal } from "@anchor/react-components/dist/lib/components";

// Utils
import useProcessedData from "../../../utils/hooks/useProcessedData";

// Services
import { postAPI } from "../../../Services/APIservices";

// Components
import { SkeletonLoader } from "../../../Components/SkeletonLoader/SkeletonLoader";
import ImpactAnalysisTable from "./components/ImpactAnalysisTable";
import ImpactProposalTable from "./components/ImpactProposalTable";

// Models
import { DtcoModelData } from "../DtcoModel";
import { ImpactAnalysisData, ProposalData } from "./ImpactProposalModel";

interface ImpactProposalModalProps {
  show: boolean;
  tableData: DtcoModelData[];
  onHide: () => void;
  onConfirm: () => void;
  requestId: string | undefined;
  deploymentUse?: boolean;
  isOld?: boolean
}

const LOADING_DELAY = 1500;

const ImpactProposalModal: React.FC<ImpactProposalModalProps> = ({
  tableData,
  requestId,
  show,
  onHide,
  deploymentUse,
  isOld
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');

  // use custom hook to process data
  const { result, proposedImpactData, baselineData } = useProcessedData(tableData);
  const handleConfirm = async () => {
    const url = `dtco-api/dtcosummary/SaveDTCOImpactAnalysis`;
    try {
      const requestSummaryIds = baselineData.map((item) => item.requestSummaryId);
      // restructure the proposedImpactData array to move the options inside the impactData object for API
      proposedImpactData.forEach((item: ProposalData) => {
        Object.keys(item).forEach((key) => {
          if (key.includes("option") || key === "baseline") {
            item.options = {
              ...item.options,
              [key]: item[key],
            };
            delete item[key];
          }
        });
      });

      const impactAnalysisData: ImpactAnalysisData[] = [
        ...result.map((item) => ({
          optionName: item.option,
          ...item.services.reduce((acc: { [key: string]: string }, service) => {
            acc[service.vesselName] = service.serviceName;
            return acc;
          }, {}),
          nopat: item.nopat,
          rank: item.rank,
        })),
      ];

      const impactPermutations = impactAnalysisData
        .map((item) => {
          const { nopat, optionName, rank, ...vessels } = item;
          return {
            optionName,
            vessels,
            nopat,
          };
        })
        .filter(
          (item) => item.optionName === "Baseline" || item.optionName === selectedOption
        );

      const proposedImpactDataFiltered = impactAnalysisData
        .filter((item) => item.optionName === selectedOption)
        .map((item) => {
          const { nopat, rank, service, ...vessels } = item;
          return vessels;
        })
        .map((item) => {
          return Object.keys(item).reduce((acc: any, key) => {
            acc[item[key]] = key;
            return acc;
          }, {});
        });

      const getVesselName = (service: string) => {
        const item = proposedImpactDataFiltered.find((item) => item[service]);
        if (item) {
          return item[service];
        }
      };

      const impactData = proposedImpactData.map((item) => {
        const options = item.options;
        const optionValue =
          options[selectedOption.replace(/\s/g, "").toLowerCase()];
        return {
          service: item.service,
          options: {
            'PNL Impact': optionValue,
          },
          vesselName: getVesselName(item.service),
        };
      });

      const data = {
        impactData: impactData,
        summaryIds: requestSummaryIds,
        requestId: parseInt(`${requestId}`),
        impactPermutations: impactPermutations,
      };
      const response = await postAPI(url, data);
      if (response) {
        navigate(`/RequestPage/FBPvalidation?requestId=${requestId}`);
      }
    } catch (error: any) {
      console.error("An error occurred:", error);
      setError(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setIsBtnDisabled(false);
    }, LOADING_DELAY);
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Modal
      actions={{
        primaryAction: (
          deploymentUse === true ? " " : !isOld && <Button disabled={isBtnDisabled} arialabel="handleConfirmtext" label="Create Proposal" id="create-proposal-btn" onClick={handleConfirm} /> 
        ),
        secondaryAction: (
          <Button label="Dismiss" onClick={onHide} variant="outlined" />
        ),
      }}
      backdropcloseactiondisabled
      dimension="large"
      heading="Result"
      onClose={onHide}
      open={show}
      showCloseIcon
    >
      {isLoading ? (
        <SkeletonLoader isLoader={true}></SkeletonLoader>
      ) : (
        <>
          <ImpactAnalysisTable result={result} />
          <ImpactProposalTable
            tableData={tableData}
            onSelect={(item) => setSelectedOption(item)}
          />
        </>
      )}
    </Modal>
  );
};

export default ImpactProposalModal;
