// Anchor Component
import { ImpactAnalysisData, ImpactResult } from "../ImpactProposalModel";
import TableV2 from "@anchor/react-components/dist/lib/components/TableV2";

// Interface
interface ImpactAnalysisTableProps {
  result: ImpactResult[];
}

const ImpactAnalysisTable = ({ result }: ImpactAnalysisTableProps) => {
  const impactAnalysisData: ImpactAnalysisData[] = [
    // check the result array and form the data for the table
    ...result.map((item) => ({
      optionName: item.option,
      ...item.services.reduce((acc: { [key: string]: string }, service) => {
        acc[service.vesselName] = service.serviceName;
        return acc;
      }, {}),
      nopat: item.nopat,
      rank: item.rank,
    })),
  ];

  // loop throgh all records impactAnalysisData and fetch the unique keys from the object expect nopat, rank and service and create an array
  const vesselsKeys = impactAnalysisData.reduce((acc: string[], item) => {
    Object.keys(item).forEach((key) => {
      if (key !== "nopat" && key !== "rank" && key !== "optionName") {
        acc.push(key);
      }
    });
    return acc;
  }, []);

  // remove the duplicates from the array
  const vessels = Array.from(new Set(vesselsKeys));

  // loop through impactAnalysisData and check if all keys are persent as in vessels2 array and if not add the key with - value
  impactAnalysisData.forEach((item) => {
    vessels.forEach((key) => {
      if (!item.hasOwnProperty(key)) {
        item[key] = "-";
      }
    });
  });

  const impactAnalysisColumns = [
    {
      accessorKey: "optionName",
      header: "Option",
      id: "optionName",
    },
    // loop to generate columns dynamically for vessels
    ...vessels.map((vessel) => ({
      accessorKey: vessel,
      id: vessel,
      title: vessel,
      header: () => <span title={vessel}>{vessel}</span>,
    })),
    {
      accessorKey: "nopat",
      header: "NOPAT",
      id: "nopat",
    },
    {
      accessorKey: "rank",
      header: "Ranking",
      id: "rank",
    },
  ];

  const getRowCSS = (row: any) => {
    const isRateLineNotAvailable = row?.original?.service === "Baseline";
    return isRateLineNotAvailable ? { color: "red" } : undefined;
  };

  return (
    <div className="impact-analysis-table">
      <TableV2
        columnData={impactAnalysisColumns}
        defaultData={impactAnalysisData}
        getCellStyle={(row) => {
          if (row?.original?.optionName === "Baseline") {
            return { backgroundColor: "#E9F6FC" };
          }
        }}
        getRowStyle={getRowCSS}
        stickyHeader={true}
        maxHeight={245}
      />
    </div>
  );
};
export default ImpactAnalysisTable;
