import {
  Notification,
  TableV2,
} from "@anchor/react-components/dist/lib/components";

// Utils
import { AppString } from "../../../../utils/AppString";
import StatusBadge from "../../../../Components/StatusBage/StatusBadge";

const SimulationRequestHistoryTable = ({ simulationRequestHistory }) => {
  const data = simulationRequestHistory.map((item) => ({
    ...item,
    submissionTime: item.submissionTime
      ? new Date(item.submissionTime).toDateString()
      : "0",
  }));

  const CustomDateTemplate = ({ row }) => {
    return new Date(row.getValue("submissionTime")).toDateString() !==
      "Sat Jan 01 2000" ? (
      <span>{new Date(row.getValue("submissionTime")).toDateString()}</span>
    ) : (
      <span>-</span>
    );
  };

  const columns = [
    {
      accessorKey: "requestName",
      header: "Request Name",
      id: "requestName",
      enableSorting: true,
      cell: ({ row }) => (
        <a href={`/RequestPage/DtcoOverviewPage/${row.original.id}`}>
          {row.getValue("requestName")}
        </a>
      ),
    },
    {
      accessorKey: "dtcoType",
      header: "DTCO Type",
      id: "dtcoType",
      enableSorting: true,
    },
    {
      accessorKey: "categorization",
      header: "Category",
      id: "categorization",
      enableSorting: true,
    },
    {
      accessorKey: "createdBy",
      header: "Created By",
      id: "createdBy",
      enableSorting: true,
    },
    {
      accessorKey: "costImpact",
      header: "Cost Impact",
      id: "costImpact",
      enableSorting: true,
    },
    {
      accessorKey: "submissionTime",
      header: "Submitted On",
      id: "submissionTime",
      enableSorting: true,
      cell: ({ row }) => <CustomDateTemplate row={row} />,
    },
    {
      accessorKey: "status",
      header: "Status",
      id: "status",
      enableSorting: true,
      alignData: "center",
      cell: ({ row }) => <StatusBadge status={row.original.status} diploymentUse={false} />,
    },
  ];

  return simulationRequestHistory?.length ? (
    <TableV2
      columnData={columns}
      defaultData={data || []}
      maxHeight={300}
      stickyHeader={true}
    />
  ) : (
    <Notification
      variant="info"
      body={AppString.simulationNoProposalHistory}
      icon="file"
    />
  );
};

export default SimulationRequestHistoryTable;
