import React from "react";
import { Button, TextArea } from "@anchor/react-components/dist/lib/components";
interface IPROPS {
  message: string;
  onSave: (id: number, value: string) => void;
  onCancel: (id: number) => void;
  id: number;
}

const CommentEditor = ({ message, onSave, onCancel, id }: IPROPS) => {
  const [editText, setEditText] = React.useState<string>(message);
  return (
    <div className="comment-editors">
      <div className="text-area-comment">
        <TextArea
          value={editText}
          id="comment"
          className="comment-text-area"
          onChange={(e) => setEditText(e.target.value)}
        />
        <div className="comment-editor-actions float-end">
          <Button
            onClick={() => onCancel(id)}
            label=""
            icon="times"
            variant="outlined"
            title="Cancel"
            fit="small"
          />
          <Button
            onClick={() => onSave(id, editText)}
            label=""
            icon="check"
            variant="primary"
            title="Save"
            fit="small"
          />
        </div>
      </div>
    </div>
  );
};
export default CommentEditor;
