import React from "react";
import ReactDOM from "react-dom/client";

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import "bootstrap/dist/css/bootstrap.min.css";
import { msalConfig } from "./authConfig.js";
import App from "./App";

// RUM imports
import { LogLevel } from "@grafana/faro-web-sdk";
import { RumInit } from "@maersk-global/telemetry-web-sdk";
import { ReactIntegration, ReactRouterVersion } from "@grafana/faro-react";
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  BrowserRouter,
} from "react-router-dom";

import "./index.scss";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();
// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: any) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});
window.addEventListener("DOMContentLoaded", function (e) {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <BrowserRouter>
      <App msalInstance={msalInstance} />
    </BrowserRouter>
  );
});

const faro = RumInit({
  app: {
    name: `${process.env.REACT_APP_NED_NAME}`,
    version: "1.0.0",
    environment: `${process.env.REACT_APP_ENVIRONMENT}`,
  },
  apiKey: `${process.env.REACT_APP_RUM_API_KEY}`,
  ignoreUrls:['http://localhost*/','.*pensieve.*'],

  instrumentations: {
    interactions: true,
    xhr: false,
    console: {
      disabledLevels: [LogLevel.TRACE, LogLevel.INFO],
    },
  },
  performanceInstrument: {
    ignoreEvents: ["msal.*"], // To ignore certain events pass a list of exact events names or regex
  },
  isolate: true,
  spanLimitInTrace: 3,
  useSendBeacon: true,
  transportBufferSize: 50,
  rateLimitBackoffMs: 5000,
  propagateTraceHeaderCorsUrls: [new RegExp(".*api.*")],
  debug: false,
  traceLog: false,
  batching: {
    enabled: true,
    // The interval in which to send event batches
    sendTimeout: 1000,
    // The size of the signal buffer before the batch is sent (overrides interval)
    itemLimit: 4,
  },
});
  faro.instrumentations.add(
    new ReactIntegration({
      // If the version of React Router is V6
      router: {
        version: ReactRouterVersion.V6,
        dependencies: {
          createRoutesFromChildren,
          matchRoutes,
          Routes,
          useLocation,
          useNavigationType,
        },
      },
    })
  );


