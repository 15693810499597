import React, { useEffect, useState } from "react";
import {
  Button,
  toastEmitter,
} from "@anchor/react-components/dist/lib/components";
import { useNavigate } from "react-router-dom";
import { appRoles } from "../../utils/Constants";
import ProposalDeatils from "./AddOnComponent/ProposalDeatils";
import Table from "./AddOnComponent/Table";
import { AppString } from "../../utils/AppString";
import { getAPI, postAPI } from "../../Services/APIservices";
import TradeOwnerListTable from "./AddOnComponent/TradeOwnerListTable";
import PermutationTable from "./AddOnComponent/PermutationTable";
import { DTCORequestProposalResponse } from "./model";
import ConfirmationModalPopUp from "./AddOnComponent/ConfirmationModalPopUp";
import StepIndicator from "../../Components/StepIndicator/StepIndicator";
import PageLoader from "../../Components/PageLoader/PageLoader";
const DeploymentValidation = () => {
  const history = useNavigate();
  const search = window.location.search;
  const loggedInUser = sessionStorage.getItem("userRole") ?? "";
  const params = new URLSearchParams(search);
  const requestID = params.get("requestId");
  const [openAcceptPopUp, setOpenAcceptPopUp] = useState(false);
  const [openRejectPopUp, setOpenRejectPopUp] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [isPageLoader, setIsPageLoader] = useState<boolean>(false);
  const [reviewersDetails, setReviewersDetails] =
    useState<DTCORequestProposalResponse>();

  const getTradeOwnerDeploymentDetails = async () => {
    setIsPageLoader(true);
    const response = await getAPI(
      `dtco-api/dtcosummary/GetDTCORequestProposalResponse/${requestID}`
    );
    if (response) {
      setReviewersDetails(response);
      setIsPageLoader(false);
    }
  };
  useEffect(() => {
    if (loggedInUser !== appRoles.DeploymentUser) {
      history("/AccessDenied");
    }
    getTradeOwnerDeploymentDetails();
  }, []);
  const handleSubmit = async (actionType: string, toastComment: string) => {
    const payload = { requestId: requestID, action: actionType };
    const response = await postAPI(
      `dtco-api/dtcosummary/SaveDeploymentUserResponseAsync`,
      payload
    );
    if (response === true) {
      toastEmitter(
        { className: "customClassName", title: toastComment },
        { type: "success" }
      );
      getTradeOwnerDeploymentDetails();
    } else {
      toastEmitter(
        { className: "customClassName", title: AppString.errorToastComment },
        { type: "error" }
      );
    }
  };
  return (
    <div className="fbpvalidation-page p-3 ">
      <h1 className="header-text mb-3"> DTCO Proposal</h1>
      <div className="bck-button">
        <Button
          label="DTCO Proposal"
          appearance="alt"
          fit="small"
          icon="arrow-left"
          variant="filled"
          onClick={() => history(`/RequestPage/DtcoOverviewPage/${requestID}`)}
        ></Button>
      </div>
      <StepIndicator
        requestID={requestID}
        triggerPoint={isPageLoader}
      ></StepIndicator>
      <PageLoader isLoader={isPageLoader} />
      <div className="data-section">
        <ProposalDeatils
          reuqestName={reviewersDetails?.dtcoRequestData?.requestName}
          createdBy={reviewersDetails?.dtcoRequestData?.createdBy}
          driversCategory={reviewersDetails?.dtcoRequestData?.dtcoCategoryName}
          dtcoType={reviewersDetails?.dtcoRequestData?.dtcoTypeName}
        />
        <div className="tableData-section mb-4">
          <h4 className="sub-header-text mb-3">Proposal from Deployment</h4>
          {reviewersDetails?.impactPermutations !== null && (
            <div className="permutation-table mb-3">
              <PermutationTable originalJSON={reviewersDetails?.impactPermutations} />
            </div>
          )}
          <Table originalJSON={reviewersDetails?.dtcoImpact} />
        </div>
        <div className="comment-section mb-4">
          <h4 className="sub-header-text comment-text mb-2">
            Comment from Deployment
          </h4>
          <div className="comment-box comment-div">
            {reviewersDetails?.comment}
          </div>
        </div>
        {reviewersDetails?.honpResponse !== null && (
          <div className="tableData-section mb-4">
            <TradeOwnerListTable
              tableData={
                reviewersDetails?.honpResponse
                  ? [reviewersDetails.honpResponse]
                  : []
              }
              sectionRole="HNOP"
              userRole={loggedInUser}
            />
          </div>
        )}
        <div className="tableData-section mb-4">
          <TradeOwnerListTable
            tableData={
              reviewersDetails?.fbpResponse
                ? [reviewersDetails.fbpResponse]
                : []
            }
            sectionRole="FBP"
            userRole={loggedInUser}
          />
        </div>
        <div className="tableData-section mb-4">
        <TradeOwnerListTable
            tableData={
              reviewersDetails?.deplymentHeadResponse
                ? [reviewersDetails.deplymentHeadResponse]
                : []
            }
            sectionRole="deploymentHead"
            userRole={loggedInUser}
          />
        </div>
        {reviewersDetails?.deploymentUserAction !== null && (
          <div className="button-section">
            {reviewersDetails?.deploymentUserAction?.showEscalateRequest && (
              <Button
                appearance="default"
                id="primary"
                variant="outlined"
                justifyItems="center"
                label="Escalate proposal"
                name="reject"
                fit="small"
                onClick={() => setOpenRejectPopUp(true)}
              />
            )}
            {reviewersDetails?.deploymentUserAction?.showAcceptRejection && (
              <Button
                appearance="default"
                id="primary"
                justifyItems="center"
                label="Accept Rejection"
                name="accept"
                fit="small"
                onClick={() => setOpenAcceptPopUp(true)}
              />
            )}
            {reviewersDetails?.deploymentUserAction?.showCompleteButton && (
              <Button
                appearance="default"
                id="primary"
                justifyItems="center"
                label="Complete"
                name="accept"
                fit="small"
                onClick={() => setCompleteModal(true)}
              />
            )}
          </div>
        )}
      </div>
      <ConfirmationModalPopUp
        isOpen={openRejectPopUp}
        onClose={() => setOpenRejectPopUp(false)}
        modalHeader={"Escalate proposal"}
        modalBody={AppString.DEPEscalated}
        modalButton={{
          primary: "Escalate",
          secondary: "Dismiss",
        }}
        submitFun={() => handleSubmit("Escalate", "Request escalated to HONP")}
      />
      <ConfirmationModalPopUp
        isOpen={openAcceptPopUp}
        onClose={() => setOpenAcceptPopUp(false)}
        modalHeader={AppString.acceptModalHeader}
        modalBody={AppString.DEPReject}
        modalButton={{
          primary: "Accept",
          secondary: "Dismiss",
        }}
        submitFun={() =>
          handleSubmit(
            "AcceptDeploymentHeadRejection",
            "Deployment Head rejection accepted"
          )
        }
      />
      <ConfirmationModalPopUp
        isOpen={completeModal}
        onClose={() => setCompleteModal(false)}
        modalHeader={AppString.completeModalTxt}
        modalBody={AppString.completeBody}
        modalButton={{
          primary: "Complete",
          secondary: "Dismiss",
        }}
        submitFun={() => handleSubmit("Completed", "Request completed")}
      />
    </div>
  );
};
export default DeploymentValidation;
