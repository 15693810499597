// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Anchor Components
import { Button, Modal, NumberStepper, TableV2, toastEmitter } from "@anchor/react-components/dist/lib/components";
//Styles
import './DisplayDelta.scss';
// Services
import { postAPI } from "../../../Services/APIservices";
// Components
import DisplayDeltaTableDetails from "./DisplayDeltaTableDetails";
// Models
import { DtcoModelDataDisplay, VesselPerformanceComparisonModel } from "./DisplayDeltaModel";
import { DtcoModelData } from "../DtcoModel";

interface ImpactProposalModalProps {
  show: boolean;
  tableData: DtcoModelData[];
  onHide: () => void;
  requestId: string | undefined;
  deploymentUse?: boolean;
}

const DisplayDeltaModal: React.FC<ImpactProposalModalProps> = ({
  tableData,
  requestId,
  show,
  onHide,
  deploymentUse,
}) => {
  const navigate = useNavigate();
  const [dispayTableData, setDisplayTableData] = useState<DtcoModelDataDisplay[]>([]);
  const filterDataForSelectedVessel = tableData?.filter((item) => item?.isImpactBaseline === true || item?.isImpactOption === true);

  // Use a Map to track unique names
  const vesselNameMap = new Map();

  // Iterate over the data and populate the Map
  filterDataForSelectedVessel?.forEach(item => {
    if (!vesselNameMap.has(item?.vesselName)) {
      vesselNameMap.set(item?.vesselName, {
        vesselName: item?.vesselName,
        baseline: item?.isImpactBaseline ? item?.nopat : 0,
        proposal: item?.isImpactOption ? item?.nopat : 0,
      });
    } else {
      // If you want to update the existing entry,we have the logic here
      // For example, you could update the baseline or proposal if needed
      const existingItem = vesselNameMap?.get(item?.vesselName);
      if (item?.isImpactBaseline) {
        existingItem.baseline = item?.nopat;
      }
      if (item.isImpactOption) {
        existingItem.proposal = item?.nopat;
      }
      vesselNameMap.set(item?.vesselName, existingItem); // Update the map with the modified object
    }
  });

  // Convert the Map values to an array VesselPerformanceComparisons
  const vesselPerformanceComparisonsList: VesselPerformanceComparisonModel[] = Array.from(vesselNameMap.values());

  useEffect(() => {
    //setting the list to get the required data
    const getRequiredTableData:DtcoModelDataDisplay[]  = filterDataForSelectedVessel?.map((item) => ({
      vesselName: item.vesselName,
      serviceId: item.serviceCode,
      roundTripDays: item.roundTripDays,
      consupmtionInTons: item.consupmtionInTons,
      consumptionInUSD: item.consumptionInUSD,
      portExpense: item.portExpense,
      roundTripCost: item.roundTripCost,
      yearlyCost: item.yearlyCost,
      isImpactBaseline: item.isImpactBaseline,
      requestSummaryId: item.requestSummaryId,
      totalFFEPerRT: calculateVolume(item),
      averageCyFFE: calculateAvgCYFFE(item),
      rtCY: calculateRTCY(item),
      yearlyCY: calculateYearlyCY(item),
      nopat: item.nopat,
      ebitRT: 1,
      result: getNopatRT(item.nopat, item.roundTripDays, 1),
      isImpactOption: item.isImpactOption,
    }));
    setDisplayTableData(getRequiredTableData);
  }, [tableData])

  //volume calculation
  const calculateVolume = (item: DtcoModelData) => {
    const hhDryDemand = item.hhDryDemand ? (item.hhDryDemand / 2) : 0;
    const hhReeferDemand = item.hhReeferDemand ? item.hhReeferDemand : 0;
    const bhDryDemand = item.bhDryDemand ? (item.bhDryDemand / 2) : 0;
    const bhReeferDemand = item.bhReeferDemand ? item.bhReeferDemand : 0;
    return hhDryDemand + hhReeferDemand + bhDryDemand + bhReeferDemand;
  }

  //AvgCYFFE  calculation
  const calculateAvgCYFFE = (item: DtcoModelData) => {
    const hhDryCY = item.hhDryCY ? item.hhDryCY : 0;
    const hhReeferCY = item.hhReeferCY ? item.hhReeferCY : 0;
    const bhDryCY = item.bhDryCY ? item.bhDryCY : 0;
    const bhReeferCY = item.bhReeferCY ? item.bhReeferCY : 0;
    const hhDryDemand = item.hhDryDemand ? (item.hhDryDemand / 2) : 0;
    const hhReeferDemand = item.hhReeferDemand ? item.hhReeferDemand : 0;
    const bhDryDemand = item.bhDryDemand ? (item.bhDryDemand / 2) : 0;
    const bhReeferDemand = item.bhReeferDemand ? item.bhReeferDemand : 0;
    const dryHH = hhDryCY * hhDryDemand;
    const reeferHH = hhReeferCY * hhReeferDemand;
    const dryBH = bhDryCY * bhDryDemand;
    const reeferBH = bhReeferCY * bhReeferDemand;
    const totalFFE = dryHH + reeferHH + dryBH + reeferBH;
    const volume = calculateVolume(item);
    if (volume === 0) {
      return 0;
    } else {
      const avgCYFFE = totalFFE / volume;
      return avgCYFFE;;
    }
  }

  //RTCY calculation
  const calculateRTCY = (item: DtcoModelData) => {
    const volume = calculateVolume(item);
    const avgCYFFE = calculateAvgCYFFE(item);
    return volume * avgCYFFE;
  }

  //YearlyCY calculation
  const calculateYearlyCY = (item: DtcoModelData) => {
    const rtCY = calculateRTCY(item);
    const roundTripDays = item.roundTripDays ? item.roundTripDays : 0;
    if (roundTripDays === 0) {
      return 0;
    } else {
      const yearlyCY = rtCY * (365 / roundTripDays);
      return yearlyCY;
    }
  }

  //NopatRT calculation
  const getNopatRT = (nopat,roundTripDays,nopatRT) => {
    if(nopat === 0){
      return 0;
    }
    const nopatPerday = nopat / 365;
    const roundTrip = roundTripDays * nopatRT;
    const resultNopat = (nopatPerday * roundTrip);
    return resultNopat;
  };

  //Display service code with different color for basline and option
  const handleServiceName = (row: any, column: any) => {
    if (row.original.serviceId && row.original.isImpactBaseline) {
      return <div className="highlight-basline-name" title={row.original.serviceId}>{row.original.serviceId}</div>
    } else if (row.original.serviceId && row.original.isImpactOption) {
      return <div className="highlight-option-name" title={row.original.serviceId}>{row.original.serviceId}</div>
    }
  }

  //Display vessel name with tooltip
  const getVesselName = (getValue) => {
    return <div className="vessel-name" title={getValue()}>{getValue()}</div>;
  }

  //Common Function to Display the cell value with tooltip
  const getCellNameValue = (getValue) => {
    const result = Math.round(getValue() * 100) / 100;
    return <div className="m-2" title={getValue()}>
      {result.toLocaleString()}
    </div>
  }

  //Creating the column for the table
  const displayImpactTableColumn = [
    {
      id: "serviceName",
      accessorKey: "serviceId",
      header: "Service",
      alignData: "center",
      size: 70,
      enableSorting: false,
      cell: ({ row, column }) => (
        handleServiceName(row, column)
      ),
    },
    {
      id: "vesselName",
      accessorKey: "vesselName",
      header: "Vessel",
      alignData: "center",
      enableSorting: false,
      size: 160,
      cell: ({ getValue, row }) => (
        <div className="m-2">
          {getVesselName(getValue)}
        </div>
      ),
    },
    {
      id: "roundTripDays",
      accessorKey: "roundTripDays",
      title: "R/T Days",
      header: () => <div title="R/T Days">R/T Days</div>,
      size: 50,
      alignData: "center",
      enableSorting: false,
      cell: ({ getValue }) => (
        getCellNameValue(getValue)
      ),
    },
    {
      id: "consupmtionInTons",
      accessorKey: "consupmtionInTons",
      header: () => <div title="R/T Bunker MT">{"R/T Bunker MT"}</div>,
      alignData: "center",
      size: 100,
      enableSorting: false,
      cell: ({ getValue }) => (
        getCellNameValue(getValue)
      ),
    },
    {
      id: "consumptionInUSD",
      accessorKey: "consumptionInUSD",
      header: () => <div title="R/T Bunker">{"R/T Bunker($)"}</div>,
      alignData: "center",
      size: 80,
      enableSorting: false,
      cell: ({ getValue }) => (
        getCellNameValue(getValue)
      ),
    },
    {
      id: "portExpense",
      accessorKey: "portExpense",
      header: () => <div title="R/T PE">{"R/T PE"}</div>,
      alignData: "center",
      size: 80,
      enableSorting: false,
      cell: ({ getValue }) => (
        getCellNameValue(getValue)
      ),
    },

    {
      id: "roundTripCost",
      accessorKey: "roundTripCost",
      header: () => <div title="R/T Cost">{"R/T Cost"}</div>,
      size: 70,
      alignData: "center",
      enableSorting: false,
      cell: ({ getValue }) => (
        getCellNameValue(getValue)
      ),

    },
    {
      id: "yearlyCost",
      accessorKey: "yearlyCost",
      header: () => <div title="Yearly Cost">{"Yr Cost"}</div>,
      size: 80,
      alignData: "center",
      enableSorting: false,
      cell: ({ getValue }) => (
        getCellNameValue(getValue)
      ),
    },
    {
      id: "totalFFEPerRT",
      header: () => <div title="Total FFE per R/T">{"Total FFE / R/T"}</div>,
      size: 100,
      accessorKey: "totalFFEPerRT",
      alignData: "center",
      enableSorting: false,
      cell: ({ getValue }) => (
        getCellNameValue(getValue)
      ),
    },
    {
      id: "avgCYFFE",
      header: () => <div title="AVG CY FFE">{"AVG CY FFE"}</div>,
      size: 90,
      enableSorting: false,
      accessorKey: "averageCyFFE",
      cell: ({ getValue }) => (
        getCellNameValue(getValue)
      ),
    },
    {
      id: "rtCY",
      accessorKey: "rtCY",
      header: () => <div title="R/T CY">{"R/T CY"}</div>,
      size: 80,
      alignData: "center",
      enableSorting: false,
      cell: ({ getValue }) => (
        getCellNameValue(getValue)
      ),
    },

    {
      id: "yearlyCY",
      accessorKey: "yearlyCY",
      header: () => <div title="Yearly CY">{"Yr CY"}</div>,
      size: 100,
      alignData: "center",
      enableSorting: false,
      cell: ({ getValue }) => (
        getCellNameValue(getValue)
      ),
    },
    {
      id: "nopat",
      accessorKey: "nopat",
      header: () => <div title="Full Year Benefit">{"Full Yr Benefit"}</div>,
      size: 100,
      cell: ({ getValue }) => (
        getCellNameValue(getValue)
      ),
      alignData: "center",
      enableSorting: false,
    },
    {
      id: "ebitRT",
      accessorKey: "ebitRT",
      header: () => <div title="EBIT R/T">{"EBIT R/T"}</div>,
      size: 80,
      alignData: "center",
      enableSorting: false,
      cell: ({ getValue, row }) => (
        showInputRange(getValue, row)
      ),
    },
    {
      id: "result",
      header: () => <div title="R/T Result">{"R/T Result"}</div>,
      size: 100,
      accessorKey: "result",
      cell: ({ getValue }) => (
        getCellNameValue(getValue)
      ),
      alignData: "center",
      enableSorting: false,
    },
  ]

  //Function to show the input range
  const showInputRange = (getValue, row) => {
    return <div className="ebit-rt">
      <NumberStepper
      fit="small"
      id="numberStepper"
      max={18}
      min={1}
      name="numberStepper"
      onBlur={function noRefCheck() { }}
      onChange={(e: any) => {
        row.original.ebitRT = e;
        row.original.result = getNopatRT(row.original.nopat, row.original.roundTripDays, e);
        setDisplayTableData([...dispayTableData]);
      }}

      value={getValue()}
    />
    </div>  
  }

  //Function to save the delta details and navigate to the next page
  const saveDeltaDetails = async () => {
    const dtcoSummaryExceptionCaseMatrics = dispayTableData.map((item) => ({
      serviceId: item.serviceId,
      vesselName: item.vesselName,
      roundTripDays: item.roundTripDays,
      consupmtionInTons: item.consupmtionInTons,
      consumptionInUSD: item.consumptionInUSD,
      portExpense: item.portExpense,
      summaryId: item.requestSummaryId,
      rtCost: item.roundTripCost,
      yearlyCost: item.yearlyCost,
      TotalFFEPerRT: item.totalFFEPerRT,
      averageCyFFE: item.averageCyFFE,
      rtCy: item.rtCY,
      yearlyCy: item.yearlyCY,
      fullYearBenefit: item.nopat,
      ebitByRtDays: item.ebitRT,
      totalAmount: item.result,
      IsBaseline: item.isImpactBaseline,
    }));

    const payload = {
      dtcoSummaryExceptionCaseMatrics: dtcoSummaryExceptionCaseMatrics,
      vesselPerformanceComparisons: vesselPerformanceComparisonsList,
      delta: 0,
      summaryIds: [0],
      requestId: requestId,
    }

    const url = `dtco-api/dtcosummary/SaveDTCOExceptionalCaseMetricsAsync`;
    const response = await postAPI(url, payload);
    try {
      if (response) {
        navigate(`/RequestPage/FBPvalidation?requestId=${requestId}`);
      }
    } catch (error: any) {
      toastEmitter(
        {
          className: "customClassName",
          title: "Error occured while saving the data.",
        },
        { type: "error" }
      );
    }
  }

  return (
    <Modal
      actions={{
        primaryAction: (
          !deploymentUse && <Button arialabel="handleConfirmtext" label="Create Proposal" onClick={saveDeltaDetails} />
        ),
        secondaryAction: (
          <Button label="Dismiss" onClick={onHide} variant="outlined" />
        ),
      }}
      height="680"
      width="94%"
      backdropcloseactiondisabled
      dimension="large"
      heading="Result"
      onClose={onHide}
      open={show}
      showCloseIcon
    >
      <>
        <div className="display-delta">
          <div className="display-delta-table">
            <TableV2
              columnData={displayImpactTableColumn}
              defaultData={dispayTableData}
              stickyHeader={true}
              maxHeight={260}
            />
          </div>
          <DisplayDeltaTableDetails data={vesselPerformanceComparisonsList} />
        </div>
      </>
    </Modal>
  );
};

export default DisplayDeltaModal;
