import React from 'react';
import './Loader.scss';
const Loader = () => {
  return (
    <div className="load">
      
      <div className="blur-circle"></div>
      <div className="load_gears">
        <div className="gear">
          <div className="center"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className="gear gear-two">
          <div className="center"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className="gear gear-three">
          <div className="center"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
      <div className="text">Generating Recomendation...</div>
    </div>
  );
};
export default Loader;
