// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtco-info-details-main .dtco-info-details {
  margin-bottom: 10px;
  padding: 5px 10px;
  margin-top: 1rem;
}
.dtco-info-details-main .dtco-info-details .formula-value {
  padding: 2px 6px;
  font-size: 14px;
  border-radius: 3px;
  color: var(--info-formula-highlight);
  background-color: var(--info-formula-highlight-bg);
}
.dtco-info-details-main .dtco-info-details .row-value {
  white-space: pre-wrap;
}
.dtco-info-details-main .dtco-info-label-search {
  font-size: var(--font-size-md);
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/pages/DtcoOverviewPage/DtcoInfo/DtcoInfo.scss"],"names":[],"mappings":"AACI;EACI,mBAAA;EACA,iBAAA;EACA,gBAAA;AAAR;AAEQ;EACI,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,oCAAA;EACA,kDAAA;AAAZ;AAEQ;EACI,qBAAA;AAAZ;AAGI;EACI,8BAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AADR","sourcesContent":[".dtco-info-details-main {\n    .dtco-info-details {\n        margin-bottom: 10px;\n        padding: 5px 10px;\n        margin-top: 1rem;\n\n        .formula-value {\n            padding: 2px 6px;\n            font-size: 14px;\n            border-radius: 3px;\n            color: var(--info-formula-highlight);\n            background-color: var(--info-formula-highlight-bg);\n        }\n        .row-value {\n            white-space: pre-wrap;\n        }\n    }\n    .dtco-info-label-search {\n        font-size: var(--font-size-md);\n        font-weight: 400;\n        line-height: 24px;\n        text-align: left;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
