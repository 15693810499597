import {
  MultiSelect,
  ReactSelect,
  toastEmitter,
  Button,
  Icon,
} from "@anchor/react-components/dist/lib/components";
import { getAPI, postAPI } from "../../../Services/APIservices";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { DtcoModel, DtcoPerformaModel, DtcoVesselModel } from "../DtcoModel";
import { StatusListForPerforma } from "../../../utils/Constants";
import { AppString } from "../../../utils/AppString";
import React from "react";
interface DtcoIterationProps {
  dtcoData?: DtcoModel;
  requestId?: string | number;
  handleDtcoData: (data: DtcoModel) => void;
  refreshRunPerforma?: boolean;
}
const DtcoIteration = ({
  requestId,
  handleDtcoData,
  refreshRunPerforma,
  dtcoData,
}: DtcoIterationProps) => {
  const [vesselList, setVesselList] = useState<DtcoVesselModel[]>([]);
  const [selectedVessel, setSelectedVessel] = useState<DtcoVesselModel[]>([]);
  const [selectedPerforma, setSelectedPerforma] = useState<any>([]);
  const [isRunLoading, setIsRunLoading] = useState(false);
  const [errorPerforma, setErrorPerforma] = useState(false);
  const [errorVessel, setErrorVessel] = useState(false);
  const [isSelectLoader, setIsSelectLoader] = useState(false);
  const [openGroups, setOpenGroups] = useState<{ [key: string]: boolean }>({});

  const getStatusStyling = (status: any, disable) => {
    if (StatusListForPerforma.includes(status)) {
      const resultStatus = "status-" + status;
      const checkDisabled = disable
        ? resultStatus + " option-disabled"
        : resultStatus;
      return checkDisabled;
    } else {
      return "status-Default";
    }
  };
  const getServicePerformaDetails = async () => {
    setIsSelectLoader(true);
    const response = await getAPI(`dtco-api/dtcosummary/GetVesselDetails`);
    if (response) {
      const res = response?.map((item) => {
        return {
          label: item.label + " (" + item.value + ")",
          value: item.value,
        };
      });
      setVesselList(res);
      setIsSelectLoader(false);
    }
  };
  useEffect(() => {
    getServicePerformaDetails();
  }, []);
  const getOptionPerforma = (inputResponse: DtcoPerformaModel[]) => {
    const selectResponse = inputResponse?.map((item) => {
      return {
        label: <div className="dtco-service-name">{item.label}</div>,
        value: item.label,
        options: item?.options?.map((option) => {
          return {
            label: (
              <div className="dtco-performa-header">
                <span className="dtco-roation-name">{option?.rotationId} </span>
                
                  <span
                    style={{ position: "absolute", right: 10 }}
                    className={` ${"status-label"} ${getStatusStyling(
                      option?.status,
                      option.disable
                    )}`}
                  >
                    {option?.status}
                  </span>
              </div>
            ),
            value: [option.value],
            isDisabled: option.disable,
          };
        }),
      };
    });
    return selectResponse;
  };
  const filterThePerforma = async (
    inputValue: string,
    callback: any
  ): Promise<any> => {
    const trimedValue: string = inputValue.replace(/\s+/g, "");
    if (trimedValue.length >= 3) {
      setOpenGroups({});
      const response = await getAPI(
        `dtco-api/dtcosummary/GetServiceProformaDetails/${trimedValue}/${requestId}`
      );

      if (response) {
        if (!isNaN(parseInt(trimedValue)) && trimedValue.length > 3) {
          const resultOpenGroups = response?.map((item) => {
            return {
              [item.label]: true,
            };
          });
          let converOpenGroupsObject = Object.assign({}, ...resultOpenGroups);
          setOpenGroups(converOpenGroupsObject);
        }
        const selectResponse = getOptionPerforma(response);
        callback(selectResponse);
      } else {
        callback([]);
      }
    }
  };

  const debounceSearch = debounce(filterThePerforma, 1000);
  const handleRunAPI = async (payload) => {
    try {
      const postAPIResponse = await postAPI(
        `dtco-api/dtcosummary/SaveDTCORequestProformaData/${requestId}`,
        payload
      );
      if (
        postAPIResponse?.StatusCode !== 400 &&
        postAPIResponse?.StatusCode !== 500 &&
        postAPIResponse?.dtcoRequestData
      ) {
        handleDtcoData(postAPIResponse);
        setIsRunLoading(false);
      } else {
        toastEmitter(
          {
            className: "customClassName",
            title: "Error occured while Running DTCO proforma and vessel data.",
          },
          { type: "error" }
        );
        setIsRunLoading(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsRunLoading(false);
    }
    setSelectedPerforma([]);
  };
  const handleSaveRequestPerforma = async () => {
    setIsRunLoading(true);
    if (
      selectedPerforma === null ||
      selectedPerforma === undefined ||
      selectedPerforma?.length === 0
    ) {
      setErrorPerforma(true);
      setIsRunLoading(false)
    }

    if (selectedVessel?.length === 0) {
      setErrorVessel(true);
      setIsRunLoading(false)
    }

    if (dtcoData?.dtcoRequestData.isExceptionalCase === true) {
      if (selectedPerforma?.length > 0 && selectedVessel?.length > 0) {
        const rotationCodes =
          selectedPerforma?.flatMap((item) => item.value) || [];
        const payload = {
          rotationCodes: rotationCodes,
          imoNumbers: selectedVessel?.map((item) => item.value),
        };
        handleRunAPI(payload);
      }
    } else {
      if (selectedPerforma?.value?.length > 0 && selectedVessel?.length > 0) {
        const payload = {
          rotationCodes: [selectedPerforma?.value?.[0]],
          imoNumbers: selectedVessel?.map((item) => item.value),
        };
        handleRunAPI(payload);
      }
    }
  };
  const handleVesselChange = (e) => {
    setSelectedVessel(e);
    setErrorVessel(false);
  };
  const handlePerformaChange = (e) => {
    setSelectedPerforma(e);
    setErrorPerforma(false);
  };
  const handleGroupToggle = (groupId: string) => {
    setOpenGroups({
      ...openGroups,
      [groupId]: !openGroups[groupId],
    });
  };

  const optionHighLight = (option) => {
    return option?.isDisabled
      ? "dtco-performa-option-disabled"
      : "dtco-performa-option-active";
  };

  return (
    <div className="dtco-iteration mb-3">
      <div className={`iteration-section ${dtcoData?.dtcoRequestData?.isExceptionalCase && 'specialcaseSection'} `}>
        <div className="row">
          <div className="col">
            <ReactSelect
              key={`key-${selectedPerforma}${refreshRunPerforma}`}
              className="react-select"
              error={errorPerforma}
              closeMenuOnSelect
              errorMessage={AppString.errMsgForRotation}
              fit="small"
              hint="Please type minimum 3 characters to get the list"
              id="selectPerforma"
              isClearable
              isMulti={dtcoData?.dtcoRequestData?.isExceptionalCase}
              loadOptions={(inputValue, callback) => {
                debounceSearch(inputValue, callback);
              }}
              isAsync
              aria-label="Performa"
              cacheOptions={false}
              label="Performa/Rotation ID/Service"
              name="selectCountry"
              onChange={handlePerformaChange}
              orientation="vertical"
              placeholder=""
              required
              suggestType="dynamic"
              value={selectedPerforma}
              width={100}
              data-testid="selectPerforma"
              components={{
                DropdownIndicator: () => null,
                Group: (props: { data: any; children: any }) => (
                  <div className="main-group">
                    <div
                      className="dtco-performa-header dtco-handle-group"
                      onClick={() => handleGroupToggle(props.data.value)}
                    >
                      <span className="dtco-roation-name">
                        {props.data.label}
                      </span>
                      <span className="dtco-icon">
                        {openGroups[props.data.value] ? (
                          <Icon name="chevron-up"></Icon>
                        ) : (
                          <Icon name="chevron-down"></Icon>
                        )}
                      </span>
                    </div>
                    {openGroups[props.data.value] && props?.children}
                  </div>
                ),
                Option: (props: {
                  data: any;
                  innerRef: any;
                  innerProps: any;
                }) => (
                  <div
                    ref={props.innerRef}
                    {...props.innerProps}
                    className={`dtco-performa-option ${optionHighLight(
                      props.data
                    )}`}
                  >
                    {props?.data?.label}
                  </div>
                ),
              }}
              closeMenuOnScroll={false}
            />
          </div>
          <div className="col">
            <MultiSelect
              className="multi-select"
              closeMenuOnSelect
              customNoOptionsMessage={<p>Not a valid option</p>}
              isLoading={isSelectLoader}
              error={errorVessel}
              errorMessage={AppString.errMsgForRotation}
              fit="small"
              isMulti
              id="selectVessel"
              isClearable
              isSearchable
              label="Vessel"
              name="selectVessel"
              onChange={handleVesselChange}
              options={vesselList}
              orientation="vertical"
              placeholder="Select by vessel"
              required
              suggestType="static"
              value={selectedVessel}
              hasSelectAll={false}
              width={100}
              aria-label="Vessel"
            />
          </div>
          <div className="col-md-1 dtco-run-btn">
            <Button
              fit="small"
              icon="play"
              id="run-btn"
              justifyItems="center"
              label="Run"
              name="run"
              loading={isRunLoading}
              title=""
              variant="filled"
              appearance="alt"
              width={100}
              onClick={handleSaveRequestPerforma}
              className="btn-run"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DtcoIteration;
