import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Input,
  MultiSelect,
} from "@anchor/react-components/dist/lib/components";
import { RunModel, listModel } from "../../Model";
import { postAPI } from "../../../../Services/APIservices";
import { toastEmitter } from "@anchor/react-components";
interface IPROPS {
  isModal: boolean;
  onClose: () => void;
  simulationData: RunModel;
  serviceList: listModel[];
  reRender: () => void;
}
const SimulationDuplicate = ({
  isModal,
  onClose,
  simulationData,
  serviceList,
  reRender
}: IPROPS) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState<listModel[]>();
  const [serviceIdsList, setServiceIdsList] = useState<any>();

  const getServiceList = () => {
    const serviceIds = simulationData?.serviceIds?.split(",");
    const listedData: listModel[] = serviceList.filter((item: any) => {
      return serviceIds.includes(item.value.toString());
    });
    setSelectedService(listedData);
    setServiceIdsList(listedData?.map((item) => item.value))
  };
  const handleRequest = async() => {
    const payload = {
      serviceIds: serviceIdsList,
      imos: [Number(simulationData?.imos) ?? ""],
      requestName: "",
    };
    const response = await postAPI(
        "simulation-api/simulation/SaveSimulationRequestAsync",
        payload
      );
      if (response === true) {
        toastEmitter(
          {
            className: "customClassName",
            title: "Request submitted successfully",
          },
          { type: "success" }
        );
        reRender();
      } else {
        toastEmitter(
          {
            className: "customClassName",
            title: "Something went wrong",
          },
          { type: "error" }
        );
      }
      oncloseModal();
  };
  const handleServiceList = (e: any) => {
    const serviceID = e?.map((item) => item.value);
    setServiceIdsList(serviceID);
  };
  useEffect(() => {
    if (isModal) {
      setIsShowModal(isModal);
      getServiceList();
    }
  }, [isModal]);
  const oncloseModal = () => {
    setIsShowModal(false);
    onClose();
  };
  return (
    <Modal
      actions={{
        primaryAction: (
          <Button label="Request" onClick={handleRequest} fit="small" />
        ),
        secondaryAction: (
          <Button
            label="Cancel"
            fit="small"
            variant="outlined"
            onClick={oncloseModal}
          />
        ),
      }}
      heading="Do you want to change the list of services before running the request ?"
      showCloseIcon
      onClose={oncloseModal}
      className="DuplicateModal"
      width="560px"
      height="500px"
      open={isShowModal}
    >
      <div className="DuplicateModal">
        <div>
          <Input
            id="duplicateVessel"
            value={simulationData?.vesselName}
            disabled
          />
        </div>
        <div className="ServiceList">
          <MultiSelect
            className="react-select"
            closeMenuOnSelect
            fit="small"
            id="selectservice"
            isClearable
            isSearchable
            onChange={(e) => {
              handleServiceList(e);
            }}
            label="Service Names(s)"
            name="serviceList"
            options={serviceList}
            orientation="vertical"
            placeholder="Select a Service from the list"
            hasSelectAll={false}
            value={selectedService}
            variant="default"
            width={100}
          />
        </div>
      </div>
    </Modal>
  );
};
export default SimulationDuplicate;
