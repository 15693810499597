import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import "./UserLoginDetails.scss";
import photo from "../../Assets/Images/mc-c-avatar.png"
const UserLoginDetails = () => {
  const [userPhoto, setUserPhoto] = useState<any>(photo);
  const [activeUserName, setActiveUserName] = useState<any>("");
  const [isSignOutOpen, setIsSignOutOpen] = useState(false);
  const { instance } = useMsal();
  let activeAccount: any;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  useEffect(() => {
    const getProfilePhoto = async () => {
      if (activeAccount) {
        const response = await instance?.acquireTokenSilent({
          account: activeAccount,
          scopes: ["user.read"],
        });

        const graphResponse = await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
          headers: {
            Authorization: `Bearer ${response.accessToken}`,
          },
        });
        if (graphResponse.ok) {
          const url = window.URL || window.webkitURL;
          const blobUrl = url?.createObjectURL(await graphResponse.blob());
          setUserPhoto(blobUrl)
        }
      }
      activeAccount = instance.getActiveAccount()  //azure login
      setActiveUserName(activeAccount?.name);
    };
    getProfilePhoto();
  }, []);
  const handleLogoutRedirect = () => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  };
  const toggle = () => {
    setIsSignOutOpen(!isSignOutOpen);
  };
  return (
    <>
      <div className="user-login-details">
        <div className="right-header-section" title={activeUserName} onClick={toggle}>
          <img
            src={userPhoto}
            alt="profile-photo"
            className="profle-photo"        
          />
          {isSignOutOpen && (
            <div className="dropdown-content">
              <span className="signout" data-testid="sign-out" onClick={handleLogoutRedirect}>
                Sign out
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default UserLoginDetails;
