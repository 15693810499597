import React, { useState } from "react";
import {
  Icon,
  Tag,
  Button,
} from "@anchor/react-components/dist/lib/components";
import ConfirmationModalPopUp from "../../FBPValidation/AddOnComponent/ConfirmationModalPopUp";
import SimulationDuplicate from "./SimulationDuplicate/SimulationDuplicate";
import { toastEmitter } from "@anchor/react-components";
import { RunModel, listModel } from "../Model";
import moment from "moment";
import { deleteAPI } from "../../../Services/APIservices";
interface IPROPS {
  data: RunModel;
  reRenderData: () => void;
  serviceListType: listModel[];
}

const SimulationResult = ({ data, reRenderData, serviceListType }: IPROPS) => {
  const status = data?.runCurrentStatus;
  const [deleteModal, setDeleteModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isDuplicateModal, setIsDuplicateModal] = useState(false);
  const maxLength = 90;
  const serviceIds = data?.serviceIds?.split(",");
  const redirectToDetails = (requestId: number) => {
    window.open(`/SimulationRequest/SimulationRequestResult/${requestId}`, '_blank');
  };
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const handleDeleteFunc = async () => {
    deleteAPI(
      `/simulation-api/Simulation/DeleteSimulationRequest/${data?.requestId}`
    ).then((response) => {
      if (response === true) {
        toastEmitter(
          { className: "customClassName", title: "Deleted sucessfully" },
          { type: "success" }
        );
        reRenderData();
      } else {
        toastEmitter(
          { className: "customClassName", title: "Something went wrong" },
          { type: "error" }
        );
        reRenderData();
      }
    });
  };
  const serviceCodes = data?.serviceCodes || "";
  const getTimeDifference = (dateTime: string | undefined) => {
    if (!dateTime) return "";
    const statusTime = new Date(dateTime).getTime();
    const currentTime = new Date(
      new Date().toISOString().slice(0, -1)
    ).getTime();
    const diffInSeconds = Math.floor((currentTime - statusTime) / 1000);

    const minutes = Math.floor(diffInSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days}d ago`;
    } else if (hours > 0) {
      return `${hours}h ago`;
    } else if (minutes > 0) {
      return `${minutes}m ago`;
    } else {
      return `${diffInSeconds}s ago`;
    }
  };
  const getStatus = (status: string) => {
    switch (status) {
      case "pending":
        return "Pending...";
      case "running":
        return (
          <>
            <Tag
              label="Running"
              onClose={function noRefCheck() {}}
              variant="info"
            />{" "}
            <span className="time-details">
              {" "}
              {getTimeDifference(data?.runCurrentStatusDateTime)}
            </span>
          </>
        );
      case "succeeded":
        return (
          <Tag
            label="Succeeded"
            onClose={function noRefCheck() {}}
            variant="success"
          />
        );
      case "failed":
        return (
          <Tag
            label="Failed"
            onClose={function noRefCheck() {}}
            variant="error"
          />
        );
        case "infeasible":
        return (
          <Tag
            label="Infeasible"
            onClose={function noRefCheck() {}}
            variant="warning"
          />
        );
      default:
        return "Pending...";
    }
  };
  return (
    <div className="simulationList p-2">
      <div className="icon-section">
        <Icon size="24" name="vessel-front" />
      </div>
      <ConfirmationModalPopUp
        isOpen={deleteModal}
        modalHeader={"Delete request"}
        onClose={() => setDeleteModal(false)}
        submitFun={handleDeleteFunc}
        modalBody={`This action cannot be undone. Please confirm if you want to proceed with deleting this request for vessel ${data?.vesselName}`}
        modalButton={{
          primary: "Delete",
          secondary: "Cancel",
        }}
      />
      <SimulationDuplicate isModal={isDuplicateModal} simulationData={data} reRender={()=> reRenderData()} serviceList={serviceListType} onClose={()=> setIsDuplicateModal(false)} />
      <div className="value-section">
        <div className="data-list">
          <div className="data-subtitle">
            <div className="upper-section">
              <div className="row">
                <div className="col-md-3">
                  <div className="header">Vessel:</div>
                  <div className="content">
                    <span>{data?.vesselName}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="header">Service list ({serviceIds.length}): </div>
                  <div className="content">
                    <div>
                      <span>
                        {showMore
                          ? serviceCodes
                          : `${serviceCodes.substring(0, maxLength)}${
                              serviceCodes.length > maxLength ? "..." : ""
                            }`}
                      </span>
                      {serviceCodes.length > maxLength && (
                        <span className="showmore" onClick={toggleShowMore}>
                          {showMore ? "Show Less" : "Show More"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="header">Status:</div>
                  <div className="content">
                    <span>{getStatus(status)}</span>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="icon-section">
                    <span className="icon-list">
                      <Button
                        appearance="default"
                        fit="small"
                        icon="trash"
                        id="primary"
                        justifyItems="center"
                        label=""
                        name="primary"
                        onClick={() => setDeleteModal(true)}
                        title="Delete"
                        variant="outlined"
                      />
                    </span>
                    <span className="icon-list">
                      <Button
                        appearance="default"
                        fit="small"
                        icon="copy"
                        id="primary"
                        justifyItems="center"
                        label=""
                        name="primary"
                        onClick={() => setIsDuplicateModal(true)}
                        title="Duplicate"
                        variant="outlined"
                      />
                    </span>
                    {status === "failed" ? (
                      <span className="icon-list">
                        <div className="hover-text">
                          <Button
                            appearance="default"
                            fit="small"
                            icon="info-circle"
                            id="primary"
                            justifyItems="center"
                            label=""
                            name="primary"
                            onClick={function noRefCheck() {}}
                            variant="outlined"
                          />
                          <span className="tooltip-text" id="top">
                            {data?.runCurrentStatusMessage}
                          </span>
                        </div>
                      </span>
                    ) : ( status === "succeeded" &&
                      <span className="icon-list">
                        <Button
                          appearance="default"
                          fit="small"
                          icon="file-eye"
                          id="primary"
                          justifyItems="center"
                          label=""
                          name="primary"
                          onClick={() => redirectToDetails(data?.requestId)}
                          title="View Result"
                          variant="outlined"
                        />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <div className="header">
                      <span>
                        Run ID :<span>{data?.runId}</span>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="header">
                      <span>
                        Created By :<span>{data?.createdBy}</span>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="header">
                      <span>
                        Request Name :<span>{data?.requestName}</span>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="header">
                      <span>
                        Run Date :
                        <span>
                          {moment(data?.createdOn).format("DD/MM/YYYY")}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SimulationResult;
