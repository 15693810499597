// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-login-details {
  display: flex;
}
.user-login-details .profle-photo {
  border-radius: 50%;
  height: 26px;
}
.user-login-details .current-user-name {
  position: relative;
  display: inline-block;
  margin: 4px 8px;
  margin-right: 3px;
}
.user-login-details .sign-out-icon, .user-login-details .signout {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.user-login-details .dropdown-content {
  margin-top: 6rem;
  margin-bottom: 6px;
  display: block;
  position: absolute;
  background-color: #42b0d5;
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px #d9d9d9;
  padding: 10px 16px;
  z-index: 1;
}
.user-login-details .right-header-section {
  display: inline-flex;
  font-family: "Maersk Text", sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  position: relative;
  text-decoration: none;
  color: rgb(53, 53, 53);
  border-width: 1px;
  border-color: rgb(207, 207, 207);
  border-style: solid;
  align-items: center;
  padding: 4px 10px;
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./src/Components/UserLogin/UserLoginDetails.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,kBAAA;EACA,YAAA;AACJ;AAEE;EACE,kBAAA;EACA,qBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AADJ;AAIE;EACE,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,oCAAA;EACA,kBAAA;EACA,UAAA;AAFJ;AAKE;EACE,oBAAA;EACA,sCAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,qBAAA;EACA,sBAAA;EACA,iBAAA;EACA,gCAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;AAHJ","sourcesContent":[".user-login-details {\n  display: flex;\n\n  .profle-photo {\n    border-radius: 50%;\n    height: 26px;\n  }\n\n  .current-user-name {\n    position: relative;\n    display: inline-block;\n    margin: 4px 8px;\n    margin-right: 3px;\n  }\n\n  .sign-out-icon, .signout {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n  }\n\n  .dropdown-content {\n    margin-top: 6rem;\n    margin-bottom: 6px;\n    display: block;\n    position: absolute;\n    background-color: #42b0d5;\n    min-width: 130px;\n    box-shadow: 0px 8px 16px 0px #d9d9d9;\n    padding: 10px 16px;\n    z-index: 1;\n  }\n\n  .right-header-section {\n    display: inline-flex;\n    font-family: \"Maersk Text\", sans-serif;\n    font-style: normal;\n    font-weight: 300;\n    line-height: 20px;\n    position: relative;\n    text-decoration: none;\n    color: rgb(53, 53, 53);\n    border-width: 1px;\n    border-color: rgb(207, 207, 207);\n    border-style: solid;\n    align-items: center;\n    padding: 4px 10px;\n    border-radius: 3px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
