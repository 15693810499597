import React, { useEffect, useRef, useState } from "react";
import { Button, Icon, LoadingIndicator, Menu, TextArea, toastEmitter } from "@anchor/react-components/dist/lib/components";
import moment from "moment";
import './DtcoComment.scss';
import { deleteAPI, getAPI, postAPI } from "../../../Services/APIservices";
import { useParams } from "react-router-dom";
import { appRoles } from "../../../utils/Constants";
import { DtcoCommentModel } from "./DtcoCommentModel";
import userIcon from "../../../Assets/Images/mc-c-avatar.png";
import ConfirmationModalPopUp from "../../FBPValidation/AddOnComponent/ConfirmationModalPopUp";
import CommentEditor from "../../../Components/CommentEditor/CommentEditor";
interface DtcoCommentProps {
    dtcoStatus?: string;
}
const DtcoComment = ({ dtcoStatus }: DtcoCommentProps) => {
    const [isComment, setIsComment] = useState(false);
    const [commentData, setCommentData] = useState<DtcoCommentModel[]>([]);
    const [isCommentText, setIsCommentText] = useState("");
    const { requestId } = useParams<{ requestId: string }>();
    const [expandModal, setExapndModal] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const userRole = sessionStorage.getItem("userRole");
    const [editingNote, setEditingNote] = useState<DtcoCommentModel | null>();
    const showActionBtn = userRole === appRoles.DeploymentUser && dtcoStatus === "DPL Assessment";
    const [editNoteSelected, setEditNoteSelected] = useState(false);
    const [deleteNoteSelected, setDeleteNoteSelected] = useState(false);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [isOverflow, setIsOverflow] = useState(false);
    const textareaRef = useRef(null);
    //API call to get the comment/notes list
    const getTheCommentList = async () => {
        setIsLoader(true);
        const response = await getAPI(`dtco-api/dtcosummary/GetDTCOCommentsAsync/${requestId}`);
        setCommentData(response);
        setIsLoader(false);
        if (response) {
            const lastElement = response[response?.length - 1];

            setTimeout(() => {
                const scroll: any = document.getElementById(`scroll${lastElement?.id}`);
                scroll?.scrollIntoView({ behavior: 'smooth' });
            }, 1000);
        }
    }
    //API call to get the comment/notes list when the component is mounted
    useEffect(() => {
        //If the comment section is opened then do the api call
        if (isComment) {
            getTheCommentList();
        }
    }, [isComment]);

    //Generic function for edited and newly added comment/notes
    const saveCommentNotes = async (notesId: number, commentNotes: string) => {
        const payload = {
            id: notesId,
            comment: commentNotes,
        }
        const postResponse = await postAPI(`dtco-api/dtcosummary/SaveDTCOCommentAsync?requestId=${requestId}`, payload);

        //API call to get the comment list after the comment/notes is saved
        getTheCommentList();
    }

    //Function for newly added comment/notes
    const handleAddMessage = () => {
        //If the comment is empty then return
        if (!isCommentText) {
            return;
        }
        //API call to save the comment/notes
        saveCommentNotes(0, isCommentText);

        //Reset the comment text after the comment is saved
        setIsCommentText("");
    }

    //Function for saving the edited comment/notes
    const handleSaveMessgae = (id: number, message: string) => {
        if (!message) {
            return;
        }
        saveCommentNotes(id, message);
        //Reset the editing note after the comment is saved
        setEditingNote(null);
        setEditNoteSelected(false);
    }

    //Function for cancelling the edited comment/notes
    const handleCancelMessage = () => {
        setEditingNote(null);
        setEditNoteSelected(false);
    };

    //Adding the auto resize functionality to the textarea
    useEffect(() => {
        const textarea: any = textareaRef.current;
        if (textarea) {
            // Reset height to auto to measure scroll height
            textarea.style.height = 'auto';
            // Check if textarea exceeds the maximum height
            setIsOverflow(textarea?.scrollHeight > 100); // 100px is our max height limit
            // Set height to scroll height
            textarea.style.height = `${textarea?.scrollHeight}px`;
            if (isOverflow) {
                textarea.classList.add('overflow');
            } else {
                textarea.classList.remove('overflow');
            }
        }
    }, [isCommentText, isOverflow]);

    //Function to check for the selected action from the menu
    const checkForSelectedAction = (e: any) => {
        if (e?.items[0]?.value === "Edit") {
            setEditNoteSelected(true);

        } else if (e?.items[0]?.value === "Delete") {
            setDeleteNoteSelected(true);
        }
        setMenuOpen(false);
    }
    const handleDeleteNotes = async () => {
        await deleteAPI(
            `dtco-api/dtcosummary/DeleteDTCOCommentAsync/${editingNote?.id}`
        ).then((response) => {
            if (response === true) {
                toastEmitter(
                    { className: "customClassName", title: "Note deleted successfully" },
                    { type: "success" }
                );
                getTheCommentList();
            } else {
                toastEmitter(
                    { className: "customClassName", title: "Something went wrong" },
                    { type: "error" }
                );
            }
        });
    };

    return (
        <div className="Comment-Box">
            <div className={`comment-section active`}>
                <div
                    data-testid="chat-circle"
                    id="chat-circle"
                    className="btn btn-raised"
                    onClick={() => setIsComment(true)}
                >
                    <div id="chat-overlay"></div>
                    <Icon name="book-open"></Icon>
                </div>
                {/* Should visible once the user clicks on the comment icon */}
                <div className={`chat-box ${expandModal ? " chat-box-expand " : ""}${isComment ? "show-chatbox" : ""} `}>
                    <div className="chat-box-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-auto text-start notes-button">
                                    <Button icon="expand" title="Expand" label="" variant="outlined" fit="small" onClick={() => setExapndModal(!expandModal)} />
                                </div>
                                <div className="col">
                                    Notes
                                </div>
                                <div className="col-auto notes-button">
                                    <Button title="Close" icon="times" label="" variant="outlined" fit="small" onClick={() => setIsComment(false)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="chat-box-body">
                        <div className="chat-box-overlay"></div>
                        <div className={`chat-logs ${expandModal ? ' chat-logs-expand' : ""}`}>
                            {isLoader ? <LoadingIndicator /> : null}
                            {
                                commentData?.map((item, index: any) => (
                                    <div className="chat-msg" id={index} key={item?.id}>
                                        <div className="chat-text" id={`scroll${item?.id}`}>
                                            <div className="user-name">
                                                <div className="user-details">
                                                    <span className="user-icon">
                                                        <img src={userIcon} alt="user-icon" />
                                                    </span>

                                                    <span className="user-name-details">
                                                        <span className="user-name-display">{item?.createdBy}</span>
                                                        <span>{moment(item.createdOn).format("DD-MM-YYYY HH:mm:ss")}</span>
                                                    </span>
                                                </div>
                                                {showActionBtn &&
                                                    <span className="timestamp" id={`${item.id}`}>
                                                        <Menu
                                                            fit="medium"
                                                            hasArrow
                                                            listData={[
                                                                {
                                                                    items: [
                                                                        {
                                                                            value: 'Edit',
                                                                        },
                                                                        {
                                                                            value: 'Delete',
                                                                        }
                                                                    ]
                                                                }
                                                            ]}
                                                            maxheight="200px"
                                                            position="top-center"
                                                            trigger="click"
                                                            width="130px"
                                                            open={editingNote?.id === item.id && menuOpen}
                                                            listChange={(e) => checkForSelectedAction(e)}
                                                        >
                                                            <div id={`${item.id}`}>
                                                                <Icon name="ellipsis-vertical" onClick={() => { setEditingNote(item); setMenuOpen(!menuOpen); setEditNoteSelected(false); setDeleteNoteSelected(false) }} />
                                                            </div>
                                                        </Menu>
                                                    </span>
                                                }
                                            </div>
                                            {editNoteSelected && editingNote && editingNote.id === item.id ?
                                                <CommentEditor message={item?.comment} id={item?.id} onSave={handleSaveMessgae} onCancel={handleCancelMessage} /> :
                                                <div className="editSection">
                                                    <span className="user-chat">{item.comment}</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {
                        deleteNoteSelected && editingNote &&
                        <ConfirmationModalPopUp
                            isOpen={deleteNoteSelected}
                            onClose={() => setDeleteNoteSelected(false)}
                            modalHeader="Delete Note"
                            modalBody="Are you sure you want to delete this note?"
                            modalButton={{
                                primary: "Delete",
                                secondary: "Dismiss"
                            }}
                            submitFun={handleDeleteNotes}
                        />
                    }
                    {/*input option should visible to only deployment user*/}
                    {showActionBtn &&
                        <div className="chat-input">
                            <TextArea
                                ref={textareaRef}
                                disabled={!showActionBtn}
                                value={isCommentText}
                                id="chat-input"
                                placeholder="Write notes..."
                                rows={1}
                                onChange={(e) => setIsCommentText(e.target.value)}
                            />
                            <div data-testid="chat-submit"
                                className={'chat-submit'}
                                title="Save"
                                id="chat-submit">
                                <Button onClick={handleAddMessage} className="message-send-icon" label="Save" variant="outlined" fit="small" />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default DtcoComment;