import * as React from "react";
import { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { postAPI, getAPI } from "../../Services/APIservices";
import { SkeletonLoader } from "../../Components/SkeletonLoader/SkeletonLoader";
import { DataRow } from "./model";
import {
  Accordian,
  Button,
  Tooltip,
  Typography,
} from "@anchor/react-components/dist/lib/components";
import StatusBadge from "../../Components/StatusBage/StatusBadge";
import CreateRequest from "./AddOnPages/CreateRequest";
import { RedirectionCase } from "./AddOnPages/RedirectionCase";
import "./RequestPage.scss";
import Filters from "./Filters";
import EditDtcoRequest from "./AddOnPages/EditDtcoRequest";
import { appRoles } from "../../utils/Constants";
import { fetchDateFormat } from "../../utils/CustomDateTime";
import { excelDownload } from "../../utils/excelDownload";
import moment from "moment";

const RequestPage = () => {
  const [rquestTableData, setRquestTableData] = useState([]);
  const [storeTableData, setStoreTableData] = useState([]); // to store the original data
  const [isTableLoader, setIsTableLoader] = useState(false);
  const mainPayload = {
    dtcoRequestTypes: [],
    statuses: [],
    dtcoCategorizations: [],
  };
  const [filterData, setFilterData] = useState({});
  const [isCreateRequestModalOpen, setIsCreateRequestModalOpen] =
    useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editRow, setEditRow] = useState<any>();
  const [isClearFilter, setIsClearFilter] = useState(false);

  const userRole = sessionStorage.getItem("userRole");
  const [accordion, setAccordian] = useState({
    filter: true,
  });

  const getRquestDetails = async (payload: object) => {
    setIsTableLoader(true);
    const response = await postAPI(`dtco-api/dtco/GetAllDTCORequests`, payload);
    const tableResponse = response;

    setRquestTableData(tableResponse);
    setStoreTableData(tableResponse);
    setIsTableLoader(false);
  };
  const editButtonHandler = (row: any) => {
    return (
      <Button
        appearance="default"
        id="primary"
        icon="pencil"
        justifyItems="center"
        label="Edit"
        name="Edit"
        disabled={row.statusName === "Completed" ? true : false}
        fit="small"
        iconPosition="right"
        onClick={() => {
          setEditModal(true);
          setEditRow(row);
        }}
        variant="outlined"
      />
    );
  };

  const getDTCORequestFilters = async () => {
      const response = await getAPI(`dtco-api/dtco/GetDTCORequestFilters`);
      setFilterData(response);
  };

  useEffect(() => {
    getRquestDetails(mainPayload);
    getDTCORequestFilters();
  }, []);
  //helper function for filtering
  const filterInKeysAndValues = (
    values: string[],
    keys: string[],
    request: any
  ) => {
    return keys.includes("requestSubmittedToTradeOwner")
      ? values.length === 0 ||
          (request[keys[0]] >= values[0] &&
            (values[1] === "" || request[keys[0]] <= values[1]))
      : values.length === 0 ||
          keys.some((key) => values.includes(request[key]));
  };

  const filterRes = (data: any, filterDataValues: Array<Object>): any => {
    return data?.filter((eachRequest: any) => {
      let isRowValid = true;
      filterDataValues?.forEach((selectedValue: any) => {
        if (selectedValue?.value?.length > 0) {
          const matchFilter = filterInKeysAndValues(
            selectedValue?.value,
            selectedValue?.key,
            eachRequest
          );
          if (!matchFilter) {
            isRowValid = false;
          }
        }
      });
      return isRowValid;
    });
  };
  const ClearFilters = () => {
    setRquestTableData(storeTableData);
  };
  const getFilteredRequestDetails = (filters: any) => {
    const payLoad = {
      dtcoRequestTypes: filters?.dtcoRequestTypes?.map(
        (item: any) => item?.label
      ),
      statuses: filters?.statuses?.map((item: any) => item?.label),
      requestCreatedUsers: filters?.requestCreatedUsers?.map(
        (item: any) => item?.label
      ),
      dtcoCategorizations: filters?.driverCategories?.map(
        (item: any) => item?.label
      ),
      executedData: filters?.executedData?.map((item: any) => item?.label),
      requestSubmittedToTradeOwner:
        filters?.requestSubmittedToTradeOwner[0] ||
        filters?.requestSubmittedToTradeOwner[1]
          ? filters?.requestSubmittedToTradeOwner
          : ["", ""],
    };
    const res = filterRes(storeTableData, [
      { value: payLoad?.dtcoRequestTypes, key: ["dtcoTypeName"] },
      { value: payLoad?.requestCreatedUsers, key: ["createdBy"] },
      { value: payLoad?.statuses, key: ["statusName"] },
      { value: payLoad?.dtcoCategorizations, key: ["dtcoCategoryName"] },
      { value: payLoad?.executedData, key: ["requestExecuted"] },
      {
        value: payLoad?.requestSubmittedToTradeOwner,
        key: ["requestSubmittedToTradeOwner"],
      },
    ]);

    setRquestTableData(res);
  };
  const handleReloadAPI = () => {
    setIsClearFilter(true);
    getRquestDetails(mainPayload).then(() => {
      setIsClearFilter(false);
    });
  };
  const handleCreateModal = () => {
    setIsCreateRequestModalOpen(true);
  };
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Name",
      selector: (row) => (row.requestName !== null ? row.requestName : ""),
      sortable: true,
      width: userRole === appRoles.DeploymentUser ? "10%" : "14%",
      cell: (row) => (
        <a
          href={RedirectionCase(
            row.id,
            row.statusName,
            userRole,
            row.isDraftRequest
          )}
          aria-label="Link to DTCO calculation page"
        >
          {row.requestName}
        </a>
      ),
    },
    {
      name: "DTCO Type",
      cell: (row) =>
        row.dtcoTypeName !== null ? (
          <span className="tooltipvalue" title={row.dtcoTypeName}>
            {row.dtcoTypeName}
          </span>
        ) : (
          "--"
        ),
      sortable: true,
      width: "12%",
    },
    {
      name: "Drivers Category",
      cell: (row) =>
        row.dtcoCategoryName !== null ? (
          <span className="tooltipvalue" title={row.dtcoCategoryName}>
            {row.dtcoCategoryName}
          </span>
        ) : (
          "--"
        ),
      sortable: true,
      width: "9%",
    },
    {
      name: "Drivers",
      cell: (row) =>
        row.drivers !== null ? (
         row.drivers !== "" ?
          <div title={row.drivers}>
            {" "}
            <Tooltip
              appearance="default"
              content={
                <span>
                  <em>I am tooltip</em>
                </span>
              }
              position="top"
              width="auto"
            >
              <span className="tooltipvalue">{row.drivers}</span>
            </Tooltip>
          </div> : "--"
        ) : (
          "--"
        ),
      sortable: false,
      width: "10%",
    },
    {
      name: "Created By",
      cell: (row) =>
        row.createdBy !== null ? (
          <span className="tooltipvalue" title={row.createdBy}>
            {row.createdBy}
          </span>
        ) : (
          "--"
        ),
      sortable: true,
      width: "13%",
    },
    {
      name: "Cost Impact",
      cell: (row) =>
        row.costImpact !== null ? (
          <span className="tooltipvalue" title={row.costImpact}>
            {row.costImpact}
          </span>
        ) : (
          "--"
        ),
      sortable: true,
      width: "10%",
    },
    {
      name: "Submitted",
      cell: (row) =>
        row.requestSubmittedToTradeOwner !== null ? (
          <span
            className="tooltipvalue"
            title={fetchDateFormat(row.requestSubmittedToTradeOwner)}
          >
            {" "}
            {fetchDateFormat(row.requestSubmittedToTradeOwner)}
          </span>
        ) : (
          "--"
        ),
      sortable: true,
      width: "9%",
    },
    {
      name: "Executed",
      selector: (row) =>
        row.requestExecuted !== null ? row.requestExecuted : "--",
      sortable: true,
      width: "7%",
    },
    {
      name: "Status",
      cell: (row) => <StatusBadge status={row.statusName} diploymentUse={row.isDeploymentUseCase} />,
      sortable: true,
      width: userRole === appRoles.DeploymentUser ? "12%" : "13%",
    },
    {
      name: "Action",
      cell: (row) =>
        userRole === appRoles.DeploymentUser ? editButtonHandler(row) : "--",
      sortable: true,
      width: userRole === appRoles.DeploymentUser ? "7%" : "0%",
    },
  ];

  const downloadExcel = () => {
    const dataSource: any[] = rquestTableData?.map((e: any) => {
      return {
        requestName: e.requestName,
        dtcoTypeName: e.dtcoTypeName,
        dtcoCategoryName: e.dtcoCategoryName,
        drivers: e.drivers,
        createdBy: e.createdBy,
        createdOn:
          e.createdOn != null ? moment(e.createdOn).format("DD-MMM-YYYY") : "",
        costImpact: e.costImpact,
        requestSubmittedToTradeOwner:
          e.requestSubmittedToTradeOwner != null
            ? moment(e.requestSubmittedToTradeOwner).format("DD-MMM-YYYY")
            : "",
        requestExecuted: e.requestExecuted,
        statusName: e.statusName,        
      };
    });

    const header = [
      "Request Name",
      "Dtco Type Name",
      "Dtco Category Name",
      "Drivers",
      "Created By",
      "Created On",
      "Cost Impact",
      "Request Submitted on",
      "Request Executed",
      "Status Name",
    ];

    excelDownload("Request_Data", dataSource, header);
  };

  return (
    <div className="request-page p-3 ">
      <h1 className="header-text">DTCO Request Page</h1>
      {userRole === appRoles.DeploymentUser && (
        <div className="action-items">
          <Button
            appearance="alt"
            id="primary"
            icon="plus"
            justifyItems="center"
            label="Create Request"
            name="CreateRequest"
            fit="small"
            onClick={handleCreateModal}
            variant="primary"
          />
        </div>
      )}
      <Accordian
        className="justify-content-center mb-1 accordian-filter"
        fit="small"
        size="small"
        onCardToggle={() => {
          setAccordian({ filter: !accordion.filter });
        }}
        expanded={accordion.filter}
        variant="vanity"
      >
        <Filters
          filterData={filterData}
          applyFilters={getFilteredRequestDetails}
          clearFilters={ClearFilters}
          isClearFilter={isClearFilter}
        />
      </Accordian>

      <div className="d-flex justify-content-between align-items-end mb-2">
        <Typography variant="h6">Proposal Overview</Typography>
        <Button
          appearance="default"
          id="export-excel"
          icon="tray-arrow-down"
          justifyItems="center"
          label="Extract Table"
          name="Excel"
          fit="small"
          iconPosition="right"
          onClick={downloadExcel}
          variant="outlined"
        />
      </div>
      <div className="table-section">
        <DataTable
          columns={columns}
          data={rquestTableData}
          progressPending={isTableLoader}
          progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
          pagination
          paginationComponentOptions={paginationComponentOptions}
        />
      </div>
      <CreateRequest
        isOpen={isCreateRequestModalOpen}
        dropDownData={filterData}
        reloadAPI={handleReloadAPI}
        onClose={() => setIsCreateRequestModalOpen(false)}
      />
      <EditDtcoRequest
        dropDownData={filterData}
        rowData={editRow}
        open={editModal}
        reloadAPI={handleReloadAPI}
        onClose={() => setEditModal(false)}
      />
    </div>
  );
};
export default RequestPage;
