export const AppString = {
  acceptModalTxt: "Are you sure you want to accept this proposal?",
  acceptModalHeader: "Accept proposal",
  acceptToastComment: "Request has been accepted successfully",
  completeModalTxt: "Complete Proposal",
  rejectedToastComment: "Request has been rejected successfully",
  errorToastComment: "Something went wrong",
  errorMsgForRequiredFields: "This is the required field",
  savedSuccessfullyToastComment: "Saved successfully",
  DEPEscalated: "Are you sure you want to escalate the proposal?",
  DEPReject: "Are you sure you want to accept rejection and complete the flow?",
  completeBody: "Are you sure you want to Complete this proposal?",
  errMsgForRotation: 'This is the required field',
  simulationResultFeedback: "Help us improve our simulation results",
  simulationResultFeedbackComment: "You choose the result which we have ranked as number {selectedServiceRank[0]}. Before you proceed, please clarify why you didn’t choose number 1.",
  simulationResultFeedbackThankYou: "Thank you for your feedback",
  simulationResultFeedbackThankYouComment: "Your feedback has been saved in the Simulation Result Log",
  simulationResultFeedbackRequired: "*This field is required",
  simulationProposalCreatedTitle: "Proposals Created From the Request",
  simulationNoFeedback: "No feedback log available to display.",
  simulationResultLogTitle: "Simulation Result Log",
  simulationNoProposalHistory: "No DTCO proposals have been generated from the solutions yet.",
};
